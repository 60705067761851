.navbar {
  display: flex;
  justify-content: space-around;
  border: 0.5px groove #171717;
  border-radius: 5px;
  width:85%;
  margin-left: 12px;
  position: absolute;
}
.link {
  padding: 6px;
  color: white;
  font-size: 17px;
  width: 34%;
  text-align: center;
  text-decoration: none!important;
  background-color: #171717;
}
.navbar a:hover {
  background-color: #0e0d0d;
  color: white;
}
.active_link {
  background-color: #f02f39;
}
.header_container {
  margin-bottom: 8px;
}
