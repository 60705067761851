/* Overlay for Modal */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
}

/* Modal Container */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 900px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    overflow-y: auto;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Header */
.header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-color: #f4f4f4;
    border-bottom: 1px solid #ddd;
    width: 100%;
}

.header h2 {
    margin: 0;
    font-size: 24px;
    color: #333;
    text-align: center;
    flex-grow: 1;
}

/* Close Button */
.closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #ff4d4d;
    transition: color 0.3s;
}

.closeButton:hover {
    color: #e60000;
}

/* Content Wrapper */
.contentWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 20px;
    gap: 30px;
    justify-content: space-between;
    width: 100%;
}

/* Left Section */
.leftSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    padding-right: 15px;
}

.rightSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    padding-left: 15px;
}

.detailsTable {
    width: 100%;
    border-collapse: collapse;
}

.detailsTable th,
.detailsTable td {
    padding: 10px;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.detailsTable th {
    background-color: #f5f5f5;
    color: #000;
}

.detailsTable td {
    color: #444;
}

/* Image and Video Styling */
.image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ddd;
}

.video {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    border: 1px solid #ddd;
}

/* Footer */
.footer {
    padding: 10px 15px;
    text-align: center;
    border-top: 1px solid #ddd;
    background-color: #f9f9f9;
    width: 100%;
}

.closeFooterButton {
    padding: 6px 18px;
    font-size: 16px;
    border-radius: 20px;
    border: 1px solid red;
    background-color: white;
    color: red;
    transition: background-color 0.3s, color 0.3s;
}

.closeFooterButton:hover {
    background-color: red;
    color: white;
}

/* Copy Icon Styling */
.copyIcon {
    margin-right: 5px;
    color: #007bff;
}

/* Responsive Design */
@media (max-width: 768px) {
    .modal {
        width: 95%;
    }

    .header h2 {
        font-size: 20px;
    }

    .contentWrapper {
        flex-direction: column;
        gap: 20px;
    }

    .detailsTable th,
    .detailsTable td {
        font-size: 12px;
        padding: 8px;
    }

    .image {
        width: 120px;
        height: 120px;
    }
}
