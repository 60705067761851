.mainBreadcrumbs {
    display: flex;
}

.slash {
    color: rgb(165, 165, 165);
    padding-left: 5px;
    padding-right: 5px;
}

@media only screen and (max-width:768px) {
    .mainBreadcrumbs {
        font-size: 14px;
    }
}