.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
}

/* Modal Container */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    /* Reduced width for better compactness */
    max-width: 800px;
    /* Adjusted max width to ensure it fits smaller screens */
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    /* Reduced shadow for a subtler effect */
    z-index: 1001;
    overflow-y: auto;
    /* Allow content to scroll if it overflows */
    max-height: 90vh;
    /* Restrict height to keep it within the viewport */
    display: flex;
    flex-direction: column;
}


.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: beige;
    border-bottom: 1px solid #ddd;
}

.header h2 {
    margin: 0;
    font-size: 22px;
    color: #333;
    flex-grow: 1;
    text-align: center;
}

/* Close Button */
.closeButton {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #ff4d4d;
    /* Red color for the close button */
    transition: color 0.3s;
    align-self: flex-end;
    /* Align the button to the end */
}

.closeButton:hover {
    color: #e60000;
    /* Darker red on hover */
}

/* Content Wrapper */
.contentWrapper {
    display: flex;
    flex-direction: column;
    /* Switch to column layout to use space better */
    padding: 15px;
    gap: 15px;
}

/* Left Section */
.leftSection {
    display: flex;
    flex-direction: flex;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
}

.section {
    width: 100%;
}

.imageContainer h3,
.videoContainer h3 {
    margin-bottom: 5px;
    /* Reduced margin for compactness */
    font-size: 16px;
    color: #333;
}

.image {
    width: 150px;
    /* Smaller size for compactness */
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ddd;
}

.video {
    width: 100%;
    /* Make the video responsive */
    max-width: 300px;
    /* Cap the width */
    height: auto;
    /* Allow height to adjust naturally */
    border-radius: 8px;
    border: 1px solid #ddd;
}

/* Right Section */
.rightSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    /* Center items horizontally */
}

.detailsTable {
    width: 100%;
    /* Ensure table takes full width */
    max-width: 700px;
    /* Set a max-width for better appearance */
    border-collapse: collapse;
}


.detailsTable {
    width: 100%;
    border-collapse: collapse;
}

.detailsTable th,
.detailsTable td {
    padding: 6px 10px;
    /* Reduced padding for compactness */
}

.detailsTable th {
    width: 30%;
    /* Adjusted width ratio */
    background-color: #f5f5f5;
    font-size: 14px;
    /* Smaller font for compactness */
}

.detailsTable td {
    font-size: 14px;
    /* Matching font size */
    color: #444;
}

.copyIcon {
    margin-right: 5px;
}

/* Footer */
.footer {
    padding: 10px 15px;
    text-align: center;
    border-top: 1px solid #ddd;
    background-color: #f9f9f9;
}

.closeFooterButton {
    padding: 4px 20px;
    /* Reduced padding for a more compact button */
    font-size: 16px;
    border-radius: 20px;
    border-color: red;
    background-color: white;
    color: red;
}

.closeFooterButton:hover {
    padding: 4px 20px;
    /* Reduced padding for a more compact button */
    font-size: 16px;
    border-radius: 20px;
    /* border-color: white; */
    background-color: red;
    color: white;
}



.educationCompany {
    display: flex;
    margin: 5px 0;
    padding: 10px 0;
}

/* .imageGallery_section {
    margin-bottom: 20px;
} */

.imageGallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.imageContainerMul {
    width: 230px;
    height: 150px;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.imageContainerMul:hover {
    transform: scale(1.05);
}

.imageMul {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Video Gallery Section */
.videoGallery_section {
    margin-bottom: 20px;
}

.videoGallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.videoContainer {
    width: 250px;
    height: 150px;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.videoContainer:hover {
    transform: scale(1.05);
}

.videomul {
    width: 100%;
    height: auto;
    object-fit: cover;
}



/* Responsive Design */
@media (max-width: 768px) {
    .modal {
        width: 95%;
        /* Almost full width on smaller screens */
    }

    .leftSection,
    .rightSection {
        width: 100%;
    }

    .image {
        width: 120px;
        /* Further reduce image size on smaller screens */
        height: 120px;
    }

    .video {
        max-width: 100%;
        /* Video will take full width on small screens */
    }

    .detailsTable th,
    .detailsTable td {
        font-size: 12px;
        /* Adjust font size for smaller screens */
    }
}