.sidebar_container {
  flex: 1;
  height: 90vh;
  color: white;
  background-color: #171717;
  position: sticky;
  overflow: auto;
}
.navbar {
  border: 0.5px groove #171717;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 8px;
  margin: auto;
}
.link {
  display: flex;
  flex-direction: row;
  padding: 8px;
  color: white;
  font-size: 17px;
  text-align: center;
  text-decoration: none !important;
  background-color: #171717;
  border-bottom: 1px groove grey;
}

.sub_menu {
  display: flex;
  flex-direction: row;
  padding: 8px;
  cursor: pointer;
  color: white;
  font-size: 17px;
  text-align: center;
  text-decoration: none !important;
  background-color: #171717;
  border-bottom: 1px groove grey;
}

.navbar a:hover {
  background-color: #1e1e1e;
  color: white;
}
.active_link {
  background-color: #f02f39;
}

.active_sub_menu {
  background-color: gray;
}
.header_container {
  margin-bottom: 8px;
}

.sub_menu_icon{
  width: 25px;
  height: 25px;
}