.dashboard_container {
  margin: 20px 0px;
  flex: 5;
  height: 85vh;
  overflow-y: auto;
  overflow-x: scroll;
  color: white;
  background-color: #1e1e1e;
}
.arrow {
  font-size: 15px;
  margin-left: 5px;
  cursor: pointer;
}
.orders_table {
  width: 100%;
  border-collapse: collapse;
  margin: 17px;
}
.table_heading {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  background-color: #383737;
  padding: 8px;
}
.table_td {
  text-align: center;
  padding: 6px;

  font-size: 14px;
  background-color: #171717;
  border-bottom: 0.5px solid #292929;
}
.table_td_approve {
  text-align: center;
  padding: 6px;
  font-weight: 700;
  font-size: 14px;
  background-color: #171717;
  border-bottom: 0.5px solid #292929;
}
.table_td_link {
  color: #f02f39;
}
.table_container {
  border-radius: 5px;
  width: 130vw;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  margin: auto;
}
.button {
  height: 6vh;
  width: 10vw;
  margin-top: 10px;
  color: white;
  background-color: #171717;
  cursor: pointer;
}
.container {
  display: flex;
  flex-direction: row;
}
.button_container {
  text-align: center;
  margin-top: 20px;
}
.spinner {
  text-align: center;
  padding-top: 20px;
}

.approve_button {
  border: none;
  border-radius: 3px;
  padding: 4px 10px;
  font-size: 13px;
  color: #ffff;
  background-color: #00b528;
}

.reject_button {
  border: none;
  border-radius: 3px;
  padding: 4px 10px;
  font-size: 13px;
  color: #ffff;
  background-color: #f02f39;
}
