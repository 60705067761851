.dateDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dateDiv>label {
  color: #fff;
  margin: 10px;
}

.buttons,
.fetchButton {
  width: 100%;
  text-align: right;
}

.buttons>button,
.fetchButton>button,
.buttons1>button {
  color: #fff;
  background-color: #f02939;
  border: 1px solid #f02939;
  transition: all 0.3s ease;
  padding: 3px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.buttons>button:hover,
.fetchButton>button:hover {
  color: #f02939;
  background-color: #fff;
  border: 1px solid #f02939;
}

.buttons1>span {
  color: #fff;
}

.fetchButton button,
.buttons1>button {
  margin: 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}

.buttons1 {
  text-align: right;
}

.jobItem {
  background-color: #d8d4d475;
  width: 100%;
  min-height: 300px;
  display: grid;
  grid-template-columns: 2fr 7.7fr;
  margin: 20px 0 0 0px;
  text-decoration: none;
  color: black;
  background-color: #fff;
  line-height: 1.2;
}

.jobItemImage {
  padding: 5px;
  border: 1px solid #bbbbbb;
  margin: auto;
  width: 270px;
  height: 300px;
}

.jobItemImage img {
  width: 100%;
  height: 100%;
  margin: auto 0;
}

.jobItemInfo {
  /* max-width: 500px; */
  margin-left: 20px;
}

.jobInfoTitle {
  margin: 10px 0;
  text-decoration: none;
}

.screen {
  background-color: #9411e0;
  /* width: 95%; */
}

.screenDiv {
  width: 95%;
  margin: auto;
  min-height: 100vh;
}

.msg {
  height: 320px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.msg p {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 10px;
}

.inboxLink {
  text-decoration: none !important;
}

.TopScreen {
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: center;
}

.btnWrapper {
  position: relative;
}

.btnn {
  position: absolute;
  bottom: 20px;
  right: 10px;
}