.d_flex {
  display: flex;
}

.TooltipBox {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #333;
  z-index: 1000;
  top: 35px;
  /* Adjust based on label height */
  left: 0;
  width: 250px;
}

/* Validation tooltip styling */
.ValidationTooltip {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  color: #333;
  z-index: 1000;
  width: 300px;
  margin-top: 5px;
}

.successMessage {
  color: rgb(7, 182, 7);
}

.errorMessage {
  color: red;
}

.imgDiv {
  max-height: 80vh;
  width: 60%;
}

.formDiv {
  width: 40%;
  max-height: 80vh;
  overflow-y: scroll;
}

.imgicon {
  width: 50px;
  height: 50px;
  margin-left: 20px;
}

.header {
  background: #9411e0;
  color: #fff !important;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header h4 {
  color: #fff;
  margin-bottom: 0;
}

.header svg {
  cursor: pointer;
}

.formDiv>div>div {
  display: flex;
  position: relative;
  margin: 20px;
  flex-wrap: wrap;
}

.formDiv>div>div>h5 {
  margin-bottom: 0;
  color: grey;
}

.formDiv>div>div>label,
.formDiv>div>div>textarea,
.formDiv>div>div>select,
.formDiv>div>div>input {
  width: 50%;
  /* padding-left: 2rem; */
}

.formDiv>div>div>label>span {
  color: #f02939;
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
}

.formDiv>div>div>p {
  width: 100%;

  /* padding-left: 2rem; */
}

/* .EditCompanyBackdrop {
  z-index: 500;
} */

.suggestion {
  position: absolute;
  max-height: 300px;
  max-width: 300px;
  overflow-x: scroll;
  background: #fff;
  top: 32px;
  z-index: 100;
  right: 0;
}

.suggestion p {
  color: #000;
  padding: 5px 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.suggestion p:hover {
  color: #fff;
  background-color: #af1816;
}

.buttons {
  /* width: 100%; */
  justify-content: flex-start;
}

.buttons>button {
  color: #fff;
  background-color: #f02939;
  border: 1px solid #f02939;
  transition: all 0.3s ease;
  padding: 3px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.buttons>button:hover {
  color: #f02939;
  background-color: #fff;
  border: 1px solid #f02939;
}

.note {
  font-size: 12px;
  /* width: 60%; */
  margin-left: auto;
}

.locationBox {
  background: #fff;
  border-radius: 5px;
  color: #000;
  width: fit-content;
  /* position: relative; */
  padding: 10px;
  margin: 10px;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.locationBox>svg {
  cursor: pointer;
  margin-left: 5px;
}

.text_left {
  text-align: left !important;
  align-items: center !important;
}

.text_left>input {
  width: unset !important;
}

.center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addNew {
  text-align: right;
  width: 100%;
}

.addNew>span {
  font-size: 10px;
  cursor: pointer;
}

.modalCreate {
  margin: 30px;
}

.modalCreate button {
  color: #fff;
  background-color: #f02939;
  border: 1px solid #f02939;
  margin-right: 10px;
  font-size: 17px;
  /* padding: 5px 30px; */
  cursor: pointer;
  transition: all 0.3s ease;
}