/* main section */
.jobAds {
    width: 100%;
    height: 90vh;
    display: grid;
    overflow: hidden;
    grid-template-columns: 6fr 4fr;

  }
    .jobAdsImageField {
      border: 10px solid #b8b4b4;
      border-radius: 10px;
      padding: 50px;
      height: 80vh;
    
     
    }
    
    .jobAdsIcons {
      display: flex;
      justify-content: flex-end;
    }
    .icon {
      margin: 0 10px;
      font-size: 20px;
    }
    
    .jobAdsImage {
      /* border: 5px solid black; */
      border-radius: 5px;
     width: 100%;
      height: 70vh;
      margin: auto;
       margin-top: 25px; 
    }


    @media (max-width:1300px) {
      .jobAdsImage{
        margin-top: 25px;
      }
    }
    .jobAdsImage img {
      width: 100%;
      height: 100%;
    }
  
  /* input field */
  .JobAdsEntry{
    width: 100%;
    height: 90vh;
    overflow-x: hidden;
    box-sizing: border-box;
    border-radius: 10px;
  }
  
  .adEntry {
    border: 1px solid #000000;
    padding: 29px 0;
    padding-bottom: 50px;
    border-top: 0spx;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .buttonDiv{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  }

  .text{
  font-size: 13px;
  font-style: italic;
  margin-left: 100px;
  color : #fff
  }
  .walkInInterviewButtons{
    display: flex;
 
      margin-top: 20px;
  }

  
  /* location mapper */
  .locationBoxWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
 
  }
  .locationBox {
    padding: 7px;
    padding-right: 20px;
    background-color: #735297;
    color: white;
    margin: 5px;
    border-radius: 12px;
    display: flex;
    position: relative;
    padding-top: 3px;
  }
  
  .loadingLayout{
    margin: auto;
  }
  .publishDiv{
    margin: 15px 0;
    width: 90%;
     display: flex;
  }
  .publishDiv label{
    font-weight: bold;
  }
  .publishDiv input{
    margin-left: 120px;
  }
  .closeIcon {
    position: absolute;
    top: 8px;
    right: 3px;
    width: 12px;
    border-radius: 20px;
    cursor: pointer;
  }
  .loadingLayout {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .label{
    color: #fff;
  }

 
 
  