.d_flex {
  display: flex;
}

.imgDiv {
  max-height: 80vh;
  width: 60%;
}
.formDiv {
  width: 100%;
  max-height: 80vh;
  overflow-y: scroll;
}
.header {
  background: #9411e0;
  color: #fff !important;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header h4 {
  color: #fff;
  margin-bottom: 0;
}
.header svg {
  cursor: pointer;
}

.suggestion {
  position: absolute;
  max-height: 300px;
  max-width: 300px;
  overflow-x: scroll;
  background: #fff;
  top: 32px;
  z-index: 100;
  right: 0;
}
.suggestion p {
  color: #000;
  padding: 5px 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.suggestion p:hover {
  color: #fff;
  background-color: #af1816;
}
.buttons {
  /* width: 100%; */
  justify-content: flex-end;
}
.buttons > button {
  color: #fff;
  background-color: #f02939;
  border: 1px solid #f02939;
  transition: all 0.3s ease;
  padding: 3px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.buttons > button:hover {
  color: #f02939;
  background-color: #fff;
  border: 1px solid #f02939;
}
.note {
  font-size: 12px;
  /* width: 60%; */
  margin-left: auto;
}
.locationBox {
  background: #fff;
  border-radius: 5px;
  color: #000;
  width: fit-content;
  /* position: relative; */
  padding: 10px;
  margin: 10px;
  font-size: 13px;
  display: flex;
  align-items: center;
}
.locationBox > svg {
  cursor: pointer;
  margin-left: 5px;
}
.text_left {
  text-align: left !important;
  align-items: center !important;
}
.text_left > input {
  width: unset !important;
}
.center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addNew {
  text-align: right;
  width: 100%;
}
.addNew > span {
  font-size: 10px;
  cursor: pointer;
}
.modalCreate {
  margin: 30px;
}
.modalCreate button {
  color: #fff;
  background-color: #f02939;
  border: 1px solid #f02939;
  margin-right: 10px;
  font-size: 17px;
  /* padding: 5px 30px; */
  cursor: pointer;
  transition: all 0.3s ease;
}


.top_div {
  flex: 5;
  display: flex;
}
.container {
  flex: 1;
  overflow: auto;  
  background-color: #171717;
  padding: 10px;
}
.form {
  border: 1px groove grey;
  color: white;
  margin: auto;
  padding: 10px;
}
.form_header {
  font-weight: 600;
  color: white;
  font-size: 22px;
}
.img_container {
  flex: 1;
  color: white;
  margin: 10px;
  background-color: #171717;
}
.image {
  height: 70vh;
  margin: 10px;
  border: 1px groove grey;
}
.Upload_button {
  background-color: #f02f39;
  color: white;
  display: flex;
  margin: 10px auto;
  text-align: center;
  font-size: 18px;
  padding: 3px 22px;
  border: none;
  border-radius: 6px;
}
.form_input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}
.inputField {
  width: 34vw;
  padding: 10px;
  background-color: #171717;
  color: #fff;
  border: 1.5px solid gray;
  display: flex;
  justify-content: space-between;
}
.fieldName {
  margin-top: 10px;
  margin-left: 10px;
  font-size: 18px;
}
.field1 {
  margin: 10px;
  margin-top: 20px;
}
.input_button {
  margin-left: 5px;
  width: 40px;
  text-align: center;
}
.inputFieldText {
  width: 30vw;
}
.event_image {
  width: 35%;
  margin: 10px;
  height: 10vh;
}
.form_header_container {
  display: flex;
  background-color: #292929;
  padding: 8px;
  justify-content: space-between;
}
.fieldName {
  margin: 10px 10px;
  font-size: 18px;
}
.inputFieldId {
  width: 34vw;
  padding: 12px;
  background-color: #171717;
  color: #fff;
  border: 1.5px solid gray;
  height: 7vh;
}
.fields1 {
  margin: 10px 10px;
}
.fieldsPaid {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  flex-direction: row-reverse;
}

.checkbox {
  display: flex;
  align-items: center;
}

.live {
  font-size: 20px;
  color: #f02f39;
}
.imgicon {
  width: 50px;
  height: 50px;
  margin-left: 20px;
}
.textEditorWrapper {
  width: 36vw;
  margin: 10px;
}
.AddHrProfessional_Description {
  padding: 12px;
  font-size: 18px;
}
.AddHrProfessional_Desc {
  width: 35vw;
  margin: 10px;
}
