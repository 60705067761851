.button {
  width: 100px;
  border: none;
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px #414441;
}
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-content: space-between;
}
.error {
  color: red;
  margin-top: 30px;
}
.error_text {
  color: white;
}
.mob_view {
  display: none;
}
.version{
  color: white;
  margin-top: 10px;
}
@media screen and (max-width: 980px) {
  .web_view {
    display: none;
  }
  .mob_view {
    display: flex;
    width: 100%;
    height: 50vh;
  }
  .mobView_text {
    margin: auto;
    color: #f02f39;
  }
}
