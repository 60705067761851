.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.ModalStyle {
  color: black;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 0;
  z-index: 1000;
  display: flex;
  height: 86vh;
  flex-direction: column;
  align-items: center;
  width: 65%;
  border-radius: 20px;
}

.ModalStyle button {
  background-color: #f02f39;
  padding: 5px 10px;
  border: none;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  margin-top: 20px;
}

.submitionGIF {
  width: 30%;
}


.eventdetail_container {
  flex: 5;
  height: 86vh;
  margin: 20px;
  width: 100%;
  overflow: auto;
  padding: 20px;
}

.eventdetail_main {
  display: flex;
  flex-direction: column;
  /* background-color: #171717; */
  /* color:rgb(197, 195, 195); */
  /* height: 86vh; */
  /* padding: 8px; */
  width: 100%;
}

.event_image {
  width: 35%;
  margin: 10px;
  height: 50vh;
}

.event_detail {
  display: flex;
  margin: auto;
}

.event_text {
  margin: 10px;
}

/* .table_th,.table_td{
    font-size: 20px;
    padding: 12px;
} */
.table_th {
  white-space: nowrap;
  display: flex;
  justify-content: start;
  align-items: start;
}

.detail_bottom {
  margin: 10px;
}

.Time_date {
  display: flex;
  justify-content: space-around;
}

.date {
  font-weight: 500;
}

.description,
.date_text {
  /* color: rgb(100, 98, 98); */
}

.BackButton {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 8px;
}

h4 {
  color: black;
  font-size: 20px;
}