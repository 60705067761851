.gridDashboard {
  /* background-color: rgba(24, 22, 22, 0.87); */
  /* min-height: 100vh; */
  padding: 40px;
}
.gridDashboardTop {
  display: flex;
  /* justify-content: space-between; */
}
.companyDetailBox {
  border: 2px solid #fff;
  padding: 10px;
  margin-left: 3rem;
  width: max-content;
}
.companyDetailBox input {
  /* width: 100%; */
}
.companyDetailBox > div {
  color: #fff;
  margin-bottom: 10px;
}
.companyDetailBox > div > h6 {
  font-size: 1rem;
  margin: 3px 0;
  display: inline;
}
.companyDetailBox > div > span {
  font-size: 13px;
  margin: 3px 0;
  display: inline;
}
.companyDetailBox > div > button,
.saveButtons > button {
  color: #fff;
  background-color: #f02939;
  border: 1px solid #f02939;
  margin-right: 10px;
  font-size: 17px;
  padding: 5px 30px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.saveButtons {
  text-align: right;
}
.companyDetailBox > div > button:hover {
  color: #f02939;
  background-color: #fff;
  border: 1px solid #fff;
}
.addWebstory > button {
  color: #fff;
  background-color: #f02939;
  border: 1px solid #f02939;
  margin-right: 10px;
  margin-top: 20px;
  font-size: 17px;
  padding: 5px 30px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.gridDashboard table {
  color: #fff;
  background-color: #212529;
  width: 2000px;
  text-align: center;
  /* height: 00px; */
}
.gridDashboard table thead {
  color: rgb(35, 207, 35);
}
.gridDashboard table tbody > tr:nth-child(odd) {
  /* border: 1px solid #fff; */
  background-color: rgba(24, 22, 22, 0.87);
}
.gridDashboard table tbody > tr > div {
  display: flex;
  align-items: center;
  height: 50px;
}
.checkbox_input {
  /* display: flex;
  align-items: center;
  height: 50px;
  width: 30px;
  justify-content: center; */
}
.cursor_pointer {
  cursor: pointer;
}
.gridDashboard table tbody td {
  border: 1px solid #fff;
  padding: 10px;
}
.gridDashboard table tbody td p {
  max-width: 200px;
  max-height: 100px;
  word-wrap: break-word;
  overflow: auto;
  margin: auto;
}
.table_div {
  width: 90vw;
  overflow: auto;
  /* max-height: 50vh; */
  margin: 20px auto;
}
.alertInput {
  position: absolute;
  top: 40vh;
  left: 45%;
  /* width: 100%; */
  text-align: center;
}
.alertInput > div {
  border: 1px solid #fff;
  max-width: 50vw;
  margin: auto;
  max-height: 16vh;
  background: #000000e0;
  border-radius: 10px;
  padding: 20px;
}
.href {
  color: #f02939;
  font-size: 20px;
  text-decoration: none;
  margin-right: 10px;
}
.bottomBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
