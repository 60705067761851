.mainDiv{
    width: 100%;
    /* height: 100vh; */
    /* overflow: auto; */
     /* overflow-x: scroll; */
  /* height: 100vh; */
      background-color: #1e1e1e;
      color: #fff;
}
.tableHeader{
    background-color: #383737;
    padding: 8px;
}
.tableMain{
    border-collapse: collapse;
    width: 100%;
}
.tableData{
    padding: 10px;
    text-align: center;
    border-bottom: 0.5px solid rgb(175, 173, 173);
}

