body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.container {
  display: flex;
}

.se-wrapper-inner {
  min-height: 200px !important;
  background-color: #1d1b1b !important;
}

.se-btn-tray {
  background-color: grey !important;
}

.se-navigation {
  background-color: #1d1b1b !important;
}

.sun-editor-editable p {
  color: white !important;
}
.sun-editor-editable h2 {
  color: white !important;
}
.se-resizing-bar {
  border-top: none !important;
}
