.dashboard_general_open {
  display: flex;
  flex-direction: row;
 margin-top: 10px;
  justify-content: space-between;
  align-items: center;
}
.dashboard_general_container {
  width: 92%;
  margin: 20px auto;
  border: 0.5px groove #171717;
  border-radius: 5px;
  padding: 0px 14px;
  background-color: #171717;
}
.card {
  display: flex;
  flex-direction: row;
}
.spinner{
  text-align: center;
 }
 .general_button{
  background-color: #171717;
  border: none;
  color: white;
}

.General_title {
  font-weight: 600;
  background-color: #292929;
  color: white;
  padding: 5px 0px 0px 12px;
  border-radius: 5px;
  font-size: 16px;
  height: 36px;
  margin-top: 0;
}
.dashboard_container {
  margin: 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  background-color: #1e1e1e;
  border-radius: 5px;
}
.LinkCard{
  text-decoration: none;
  color: #fff;
}