.top_div {
    flex: 5;
    display: flex;
  }
  .container {
    flex: 1;
    overflow: auto;
    height: 86vh;
    background-color: #171717;
    margin: 10px;
  }
  .form {
    /* width: 60%; */
    border: 1px groove grey;
    color: white;
    margin: auto;
    margin: 10px;
  }
  .form_header {
    font-weight: 600;
    /* background-color: #292929; */
    color: white;
    /* padding: 8px; */
    /* border-radius: 5px; */
    font-size: 22px;
  }
  .img_container {
    flex: 1;
    color: white;
    margin: 10px;
    background-color: #171717;
  }
  .image {
    height: 70vh;
    margin: 10px;
    border: 1px groove grey;
  }
  .Upload_button {
    background-color: #f02f39;
    color: white;
    display: flex;
    width: 100px;
    text-align: center;
    font-size: 22px;
    padding: 5px 14px;
    margin: auto;
    border-radius: 10px;
  }
  .form_input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
  }
  .form_header_container{
    display: flex;
    background-color: #292929;
    padding: 8px;
    justify-content: space-between;
  }
  .textEditorWrapper{
    width: 500px;
    height: 300px;
    background-color: red;
  }