.eventdetail_container {
  flex: 5;
  height: 86vh;
  margin: 20px;
  overflow: auto;
}
.eventdetail_main{
    display: flex;
    flex-direction: column;
    background-color: #171717;
    color:rgb(197, 195, 195);
    /* height: 86vh; */
    padding: 8px;
    width: 100%;
}
.event_image {
  width: 35%;
  margin: 10px;
  height: 50vh;
}
.event_detail {
  display: flex;
  margin: auto;
}
.event_text{
margin: 10px;
}
.table_th,.table_td{
    font-size: 20px;
    padding: 12px;
}
.detail_bottom{
    margin: 10px;
}
.Time_date{
  display: flex;
justify-content: space-around;
}
.date{
  font-weight: 500;
}
.description, .date_text{
  color: rgb(100, 98, 98);
}
.BackButton{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 8px;
}
.dropdownMenu {
  position: absolute; /* Make it float above the page content */
  top: 100%; /* Align it directly below the input field */
  left: 0;
  right: 0;
  max-height: 200px; /* Limit the height of the dropdown */
  overflow-y: auto; /* Add scroll if the list is too long */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px; /* Optional: rounded corners */
  z-index: 1000; /* Ensure it appears on top of other elements */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: subtle shadow for visibility */
}

.dropdownItem {
  padding: 8px 12px;
  cursor: pointer;
  background-color: #fff;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}
