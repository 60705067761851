.custom_date {
  background-color: #171717;
  display: flex;
  padding: 4px;
  border-radius: 5px;
  font-size: 20px;
  height: 36px;
}
.input{
  margin: 2px;
}
.partner_companies_table {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
  margin: auto;
}
.table_heading {
  text-align: center;
  font-size: 18px;
  /* font-weight: 500; */
  background-color: #1e1e1e;
  padding: 4px;
}
.table_td {
  text-align: center;
  padding: 7px;
  font-size: 16px;
  background-color: #171717;
  border-bottom: 0.5px solid #292929;
}
.table_container {
  border-radius: 5px;
  width: 98%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  margin: auto;
  margin-top: 40px;
}
.spinner{
  text-align: center;
  padding-top: 20px;
 }
 .redirectionUrl{
   text-decoration: none;
   cursor: pointer;
 }
 .redirectionUrl:hover{
   color: #f02f39;
 }