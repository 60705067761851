
  .flexContainer > div {
  padding-top:10px;
  }

  .paginateCtn{
      display: flex;
      justify-content: flex-end;
  }

  .roundEffect{
    cursor:pointer;
    font-size:12px;
    display: flex;
    color:#FFF;
    padding-left: 6px;
    padding-right: 6px;
    text-decoration: none;
    align-items: center;
  }

  .roundEffect:hover a {
    text-decoration: none;
    color:#FFF;
  }
  .navigationIcons{
    font-size: 15px;
    margin-top: 1px;
    color: #FFF;
  }
  
  