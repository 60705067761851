.App {
  min-height: 100vh;
  min-width: 100%;
  background-color: rgb(36, 35, 35);
}
/* .screen {
  width: 95%;
  margin: auto;
} */
.loader {
  display: flex;
  justify-content: center;
  width: 100%;
}
.modal-90w {
  width: 90%;
  max-width: none !important;
}
.modal-90w > .modal-content {
  background-color: rgba(0, 0, 0, 0.774);
  color: #fff;
}
