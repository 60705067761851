.top_div {
  flex: 5;
  display: flex;
}
.container {
  flex: 1;
  overflow: auto;
  height: 86vh;
  background-color: #171717;
  margin: 10px;
}
.MultipleImgSelect {
  height: 70px;
  width: 70px;
  margin: 8px;
}
.ImageUrlContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
  max-width: 20%;
}
.CopyURLIcon {
  margin-left: -20px;
  color: #fff;
  cursor: pointer;
  margin-top: 60px;
}
.CopyURLIcon:hover {
  color: #f44336;
}
.MapImg {
  display: flex;
  flex-wrap: wrap;
}
.form {
  border: 1px groove grey;
  color: white;
  margin: auto;
  margin: 10px;
}
.form_header {
  font-weight: 600;
  color: white;
  font-size: 22px;
}
.img_container {
  flex: 1;
  color: white;
  margin: 10px;
  background-color: #171717;
}
.image {
  height: 70vh;
  margin: 10px;
  border: 1px groove grey;
}
.Upload_button {
  background-color: #f02f39;
  color: white;
  display: flex;
  text-align: center;
  font-size: 18px;
  padding: 3px 22px;
  border: none;
  margin: auto;

  border-radius: 6px;
  margin-bottom: 14px;
}

.MapImg {
  display: flex;
  flex-wrap: wrap;
}

.form_input {
  display: flex;
  flex-direction: row;
  justify-content: center;

  align-content: center;
}
.inputField {
  width: 34vw;
  padding: 10px;
  background-color: #171717;
  color: #fff;
  border: 1.5px solid gray;
  display: flex;
  justify-content: space-between;
}
.fieldName {
  margin-top: 10px;
  margin-left: 10px;
  font-size: 18px;
}
.field1 {
  margin: 10px;
  margin-top: 20px;
}
.input_button {
  margin-left: 5px;
  width: 40px;
  text-align: center;
}
.inputFieldText {
  width: 30vw;
}
.event_image {
  width: 35%;
  margin: 10px;
  height: 10vh;
}
.form_header_container {
  display: flex;
  background-color: #292929;
  padding: 8px;
  justify-content: space-between;
}
.fieldName {
  margin: 10px 10px;
  font-size: 18px;
}
.inputFieldId {
  width: 34vw;
  padding: 12px;
  background-color: #171717;
  color: #fff;
  border: 1.5px solid gray;
  height: 7vh;
}
.fields1 {
  margin: 10px 10px;
}
.fieldsPaid {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  flex-direction: row-reverse;
}

.checkbox {
  display: flex;
  align-items: center;
}

.live {
  font-size: 20px;
  color: #f02f39;
}
.imgicon {
  width: 100px;
  margin-left: 10px;
  margin-bottom: 15px;
}

.imageDiv {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.images {
  width: 90px;
  padding: 10px;
}

.dflex {
  display: flex;
}

.textEditorWrapper {
  width: 36vw;
  margin: 10px;
}
.AddHrProfessional_Description {
  padding: 12px;
  font-size: 18px;
}
.AddHrProfessional_Desc {
  width: 35vw;
  margin: 10px;
}
