.orders_table {
  width: 100%;
  border-collapse: collapse;
  margin: 17px;
  margin-top: 50px;
}
.table_heading {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  background-color: #1d1a1a;
  padding: 4px;
}
.table_td {
  text-align: center;
  padding: 6px;
  font-size: 12px;
  background-color: #171717;
  border-bottom: 0.5px solid #292929;
}
.table_td_link {
  color: #f02f39;
}
.table_container {
  border-radius: 5px;
  width: 130vw;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  /* margin: auto; */
}
.button {
  height: 6vh;
  width: 10vw;
  margin-top: 10px;
  color: white;
  background-color: #171717;
  cursor: pointer;
}
.container {
  display: flex;
  flex-direction: row;
}
.button_container {
  text-align: center;
  margin-top: 20px;
}
.spinner {
  text-align: center;
  padding-top: 20px;
}
.button_container_Excel {
  display: flex;
  width: 10vw;
  justify-content: space-between;
}
.button_articleList {
  padding: 5px;
  width: 65px;
  cursor: pointer;
}
.arrow {
  font-size: 20px;
  margin-left: 5px;
  cursor: pointer;
}
