.container {
    flex: 5;
    height: 86vh;
    margin-top: 20px;
    overflow: auto;
    color: white;
}

.main_div {
    width: 80vw;
    margin: auto;
}

.arrow {
    font-size: 15px;
    margin-left: 5px;
    cursor: pointer;
}

.header {
    padding: 12px;
    background-color: #383737;
    width: 80vw;
    font-weight: 800;
}

.spinner {
    text-align: center;
    padding-top: 20px;
}

.search_container {
    display: flex;
    /* width: 65vw; */
    font-weight: 800;
    margin-top: 50px;
    margin-bottom: 20px;
    justify-content: end;
}

.title_input {
    padding: 10px;
    width: 18vw;
    margin: 5px;
    color: white;
    background-color: #1e1e1e;
    border: none;
    border-bottom: 1px solid #383737;
}

.articleLink:link {
    color: red;
}

.articleLink:visited {
    color: green;
}

.option_input {
    padding: 8px;
    width: 18vw;
    color: white;
    background-color: #1e1e1e;
    border: hidden;
    border-bottom: 1px solid #383737;
}

.button {
    background-color: #f02f39;
    color: #fff;
    border: 1px solid #f02f39;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    padding: 3px;
    width: 10vw;
    border-radius: 6px;
}

.button:hover {
    background-color: #fff;
    color: #f02f39;
}

.button_container {
    display: flex;
    width: 26vw;
    margin-top: 25px;
    justify-content: start;
}

.button_articleList {
    padding: 5px;
    width: 65px;
    cursor: pointer;
}

.serachErrorText {
    text-align: center;
    margin-top: 30px;
    color: #f02f39;
}

.orders_table {
    width: 100%;
    border-collapse: collapse;
}

.table_heading {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    background-color: #383737;
    padding: 4px;
    border-bottom: 0.5px solid #292929;
    border-right: 0.5px solid #292929;
}

.table_td {
    text-align: center;
    padding: 6px;
    font-size: 12px;
    background-color: #171717;
    border-bottom: 0.5px solid #292929;
    border-right: 0.5px solid #292929;
}

.articleLinkDelete {
    cursor: pointer;
}

.articleLinkDelete:hover {
    color: #f02f39;
}

@media print {
    @page {
        size: landscape;
    }

    .orders_table {
        color: #fff;
        width: 100%;
        border-collapse: collapse;
        /* margin: 17px; */
        margin-top: 50px;
    }
}

.dateDiv {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 60%;
}

.filter {
    margin-top: 10px;
}

.dateLable {
    margin: 0px 20px 0px 20px;
}

.DateFilter_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.ArticleUrl {
    text-decoration: none;
    border: 1px solid gray;
    padding: 5px 8px;
    border-radius: 6px;
    background-color: rgb(128, 122, 122);
    color: #fff;
    font-size: 14px;
}

.imageShow {
    width: 50px;
    height: 50px;
    border-radius: 8px;
}

.buttons {
    margin: 0px 0px 10px 0px;
    padding: 10px 10px 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttons1 {
    margin: 10px 0px 0px 0px;
    padding: 10px 10px 0px 10px;
    display: flex;
    justify-content: space-between;
}

.buttonone {
    color: #fff;
    background-color: #f02f39;
    padding: 3px 17px;
    border: none;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 14px;
    border-radius: 5px;
}

/* QuestionList.module.css */

.searchInputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 20px 0;
    width: 100%;
}

.searchLabel {
    font-size: 1.3rem;
    /* Increase the font size */
    margin-bottom: 10px;
    color: #ED2201;
}

.searchInput {
    width: 70%;
    /* Wider search input */
    height: 40px;
    /* Increase the height */
    font-size: 1rem;
    /* Larger text size */
    padding: 10px 20px;
    /* Padding inside the input */
    border: 2px solid #f0786f;
    /* Border styling */
    border-radius: 10px;
    /* Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Light shadow */
    transition: border-color 0.3s, box-shadow 0.3s;
    /* Transition effects */
}

/* Change border and shadow on focus */
.searchInput:focus {
    border-radius: 10px;
    border-color: #ED2201;
    /* Blue border color on focus */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Stronger shadow on focus */
    outline: none;
    /* Remove default outline */
}