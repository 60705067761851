.container {
  flex: 5;
  height: 86vh;
  margin-top: 20px;
  overflow: auto;
  color: white;
}

.main_div {
  width: 80vw;
  margin: auto;
}

.arrow {
  font-size: 15px;
  margin-left: 5px;
  cursor: pointer;
}

.header {
  padding: 12px;
  background-color: #383737;
  width: 80vw;
  font-weight: 800;
}

.spinner {
  text-align: center;
  padding-top: 20px;
}

.search_container {
  display: flex;
  /* width: 65vw; */
  font-weight: 800;
  margin-top: 50px;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.title_input {
  padding: 10px;
  width: 18vw;
  margin: 5px;
  color: white;
  background-color: #1e1e1e;
  border: none;
  border-bottom: 1px solid #383737;
}

.articleLink:link {
  color: red;
}

.articleLink:visited {
  color: green;
}

.option_input {
  padding: 8px;
  width: 18vw;
  color: white;
  background-color: #1e1e1e;
  border: hidden;
  border-bottom: 1px solid #383737;
}

.button {
  background-color: #fff;
  color: #f02f39;
  border: 1px solid #f02f39;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  padding: 3px 7px;
  width: max-content;
  border-radius: 6px;
}

.button:hover {
  background-color: #f02f39;
  color: #fff;
}

.button_container {
  display: flex;

  margin-top: 25px;
  margin-bottom: 25px;
  justify-content: flex-start;
}

.count {
  font-size: 20px;
  margin-right: 20px;
}

.button_articleList {
  padding: 5px;
  width: 65px;
  cursor: pointer;
}

.serachErrorText {
  text-align: center;
  margin-top: 30px;
  color: #f02f39;
}

.orders_table {
  width: 100%;
  border-collapse: collapse;
}

.table_heading {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  background-color: #383737;
  padding: 4px;
  border-bottom: 0.5px solid #292929;
  border-right: 0.5px solid #292929;
}

.table_td {
  text-align: center;
  padding: 6px;
  font-size: 12px;
  background-color: #171717;
  border-bottom: 0.5px solid #292929;
  border-right: 0.5px solid #292929;
}

.articleLinkDelete {
  cursor: pointer;
}

.articleLinkDelete:hover {
  color: #f02f39;
}

@media print {
  @page {
    size: landscape;
  }

  .orders_table {
    color: #fff;
    width: 100%;
    border-collapse: collapse;
    /* margin: 17px; */
    margin-top: 50px;
  }
}

.dateDiv {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.dateLable {
  margin: 0px 20px 0px 20px;
}

.DateFilter_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ArticleUrl {
  text-decoration: none;
  border: 1px solid gray;
  padding: 5px 8px;
  border-radius: 6px;
  background-color: rgb(128, 122, 122);
  color: #fff;
  font-size: 14px;
}

.imageShow {
  width: 50px;
  height: 50px;
  border-radius: 8px;
}

.buttons1 {
  margin: 10px 0px 0px 0px;
  padding: 10px 10px 0px 10px;
  display: flex;
  justify-content: space-between;
}

.buttonone {
  color: #fff;
  background-color: #f02f39;
  padding: 3px 17px;
  border: none;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 14px;
  border-radius: 5px;
}