.dashboard_container {
  margin: 20px 0px;
  flex: 5;
  height: 85vh;
  overflow-y: auto;
  overflow-x: scroll;
  color: white;
  background-color: #1e1e1e;
}

.TimsbiBreadcrumb {
  margin-left: 8px;
}

.arrow {
  font-size: 20px;
  margin-left: 5px;
  cursor: pointer;
}

.form_error {
  color: #f02f39;
  font-size: 14px;
  text-align: start;
  font-style: italic;
  margin-top: 5px;
}

.orders_table {
  width: 100%;
  border-collapse: collapse;
  margin: 17px;
}

.table_heading {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  background-color: #383737;
  padding: 8px;
}

.table_td {
  text-align: center;
  padding: 6px;
  font-size: 13px;
  background-color: #171717;
  border-bottom: 0.5px solid #292929;
}

.table_td_link {
  color: #f02f39;
}

.table_container {
  border-radius: 5px;
  width: 100%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  margin: auto;
}

.button {
  height: 6vh;
  width: 10vw;
  margin-top: 10px;
  color: white;
  background-color: #171717;
  cursor: pointer;
}

.button_container {
  text-align: center;
  margin-top: 20px;
}

.spinner {
  text-align: center;
  padding-top: 20px;
}

.events_title {
  /* background-color: #171717; */
  padding: 5px 0px 0px 12px;
  border-radius: 5px;
  font-size: 20px;
  height: 35px;
  margin: 10px;
  margin-bottom: 8px;
}

.button_container {
  display: flex;
  width: 10vw;
  margin: 25px;
  justify-content: space-between;
}

.button_articleList {
  padding: 5px;
  width: 65px;
  cursor: pointer;
}

.dateLable {
  margin: 0px 20px 0px 20px;
}

.dateDiv {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  width: 60%;
}

.DateFilter_container {
  display: flex;
  padding-right: 20px;
  align-items: center;
  justify-content: flex-end;
}

.table_td_image {
  text-align: center;
  /* padding: 8px; */
  /* width: 81px; */
  width: 50px;
  object-fit: cover;
  height: 50px;
  /* background-color: #724040; */
  border-radius: 50%;
}

.card {
  display: flex;
  width: 15%;
  margin: 10px 10px;
  padding: 5px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%);
  background-color: #292929;
  border-radius: 5px;
}

.count {
  font-size: 20px;
  margin-left: 20px;
}

.count_main {
  margin-left: 10px;
  margin-top: 2px;
  font-size: 19px;
}

.No_data {
  font-size: 20px;
  margin-left: 20px;
  color: #f02f39;
}

.url_div {
  margin-top: 8px;
}

.buttonone {
  color: #fff;
  background-color: #f02f39;
  padding: 3px 17px;
  border: none;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 14px;
  border-radius: 5px;
}

.buttons1 {
  margin: 10px 0px 0px 0px;
  padding: 10px 10px 0px 10px;
  display: flex;
  justify-content: space-between;
}

.buttonone {
  color: #fff;
  background-color: #f02f39;
  padding: 3px 17px;
  border: none;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 14px;
  border-radius: 5px;
}

.Addbtn {
  padding: 5px 15px;
  color: #fff;
  background-color: #f02f39;
  border: none;
  font-size: 14px;
  font-weight: 700;
  border-radius: 5px;
}

.Edit_link {
  text-decoration: none;
  color: #fff;
}

.Edit_link:hover {
  color: #fff;
}

.Edit_link_two {
  color: #f02f39;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
}

.Edit_link_two:hover {
  color: #ffffff;
}

.isLive {
  color: rgb(0, 112, 0);
  font-weight: 700;
  font-size: 16px;
}

.isnotLive {
  color: rgb(202, 2, 2);
  font-weight: 700;
  font-size: 16px;
}

.IconImage {
  text-align: center;
  /* padding: 8px; */
  /* width: 81px; */

  width: 50px;
  object-fit: cover;
  height: 50px;
  /* background-color: #724040; */
  border-radius: 50%;
}

.delbtn {
  color: #fff;
  border-radius: 4px;
  border: none;
  background-color: #f02f39;
  font-size: 14px;
  font-weight: 600;
}

/* .flex {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-left: 5px;
  } */
.div {
  cursor: pointer;
  font-size: 20px;
}

.btnun {
  border: none;
  color: #ffffff;
  background-color: #af4444;
  border-radius: 5px;
  padding: 3px 10px;
}

.btnapp {
  border: none;
  color: #ffffff;
  background-color: #6a8547;
  border-radius: 5px;
  padding: 3px 10px;
}

.Btn {
  display: flex;
  justify-content: flex-end;
}