.NewArticle {
  border: 1px solid #1d1b1b;
  height: 86vh;
  overflow: auto;
  flex: 5;
}

/* Tooltip styling */
.TooltipIcon {
  margin-left: 5px;
  cursor: pointer;
  color: #007bff;
}

.TooltipBox {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #333;
  z-index: 1000;
  top: 35px;
  /* Adjust based on label height */
  width: 250px;
  margin-left: 20px;
}

/* Validation tooltip styling */
.ValidationTooltip {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #333;
  z-index: 1000;
  width: 300px;
  margin-top: 5px;
}

.successMessage {
  color: rgb(7, 182, 7);
}

.errorMessage {
  color: red;
}



.ArticleHeading {
  background-color: #287d61;
  font-weight: 500;
  padding: 10px;
}

.FormContainer {
  display: flex;
  height: 95%;
  background-color: #333;
}

.leftSide {
  flex: 5;
  padding: 10px 35px;
}

.formGroup {
  margin: 10px 0;
}

.formGroup label {
  font-weight: 400;
  font-family: inherit;
  color: white;
}

.formGroup input {
  font-family: inherit;
  padding: 2px 5px;
  border-radius: 10px;
  background-color: #1d1b1b;
  color: white;
}

.formGroup textarea {
  padding: 5px;
  font-family: inherit;
  resize: none;
  overflow-y: auto;
  display: block;
  width: 100%;
  border-radius: 10px;
  background-color: #1d1b1b;
  color: white;
}

.formGroup input[name="title"] {
  width: 100%;
  display: block;
  border-radius: 10px;
}

.NewArticle input:focus,
textarea:focus {
  border-color: #007084;
  outline: none;
}

.content {
  /* width: 605px;
  height: 300px; */
  border: 1px solid grey;
  overflow-y: auto;
}

.imageVideo {
  display: flex;
  justify-content: space-between;
}

.imageVideo input[type="file"] {
  border-radius: 10px;
  padding: 0;
  height: auto;

}

.submitDiv {
  text-align: center;
  margin: 0px 5px;
}

.submitButton {
  font-family: inherit;
  background: #f44336;
  color: white;
  padding: 5px 10px;
  border-radius: 6px;
  cursor: pointer;
  border: none;
}

.rightSide {
  flex: 3;
  padding: 10px;
  border-left: 3px solid rgb(204, 194, 194);
}

.otherDetailHeading {
  border-bottom: 3px solid #525050;
  padding-bottom: 5px;
  color: white;
}

.rightSide input[type="text"] {
  width: 100%;
  padding: 2px 5px;
  border-radius: 10px;
  background-color: #1d1b1b;
  color: white;
}

.rightSide select {
  width: 100%;
  padding: 2px 5px;
  border-radius: 10px;
  background-color: #1d1b1b;
  color: white;
}

.rightSide input[type="file"] {
  border: 1px solid grey;
  width: 100%;
  padding: 0;
  border-radius: 10px;
  background-color: #1d1b1b;
  color: white;
}

.checkBoxGroup {
  display: flex;
  align-items: center;
}

.checkBoxGroup input {
  margin-left: 10px;
}

.CheckBoxStyle {
  display: flex;
  justify-content: space-between;

}

.articlemultipleImagea {
  display: flex;
  /* width: 150px;
 height: 100px; */
  align-items: center;
  margin-top: 10px;
  overflow: hidden;
  flex-wrap: wrap;

}

.image_top {
  height: 70px;
  margin: 8px;
  width: 70px;
}

.Category_map {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.CategoryMap {
  color: #fff;
  border: 1px solid;
  margin: 4px 12px;
  padding: 4px 12px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  width: 40%;
}

.ErrorMessage {
  color: #ff2222;
  text-align: start;

  font-size: 14px;
  margin: 0px;
}

.ButtonContainer {
  display: flex;
  justify-content: center;
}

.MultipleImgSelect {
  height: 70px;
  width: 70px;
  margin: 8px;
}

.RemoveImgIcon {
  margin-bottom: 70px;
  margin-left: -16px;
  color: white;
  cursor: pointer;
}

.MapImg {
  display: flex;
  flex-wrap: wrap;
}

.ImageUrlContainer {
  display: flex;
}

.CopyURLIcon {
  margin-left: -20px;
  color: #fff;
  cursor: pointer;
  margin-top: 60px;
}

.CopyURLIcon:hover {
  color: #f44336;
}

.loaderContainer {
  position: fixed;
  z-index: 50;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  backdrop-filter: brightness(60%);
  height: 100vh;
  width: 100vw;
}

.required {
  color: #f02939 !important;
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
}