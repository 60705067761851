.input {
  display: flex;
  flex-direction: column;
}

.required {
  color: red;
  margin-left: 5px;
}

.fieldName {
  margin: 10px 10px;
  font-size: 18px;

}

.fields1,
.fields2 {
  margin: 10px 10px;
}

.inputField {
  width: 34vw;
  padding: 10px;
  background-color: #171717;
  color: #FFF;
  border: 1.5px solid gray;
}

.dropdownInput {
  width: 35vw;
  padding: 10px;
  background-color: #171717;
  color: #FFF;
  border: 1.5px solid gray;
}

.multiLineTextBox {
  height: 11vh;
  width: 34vw;
  padding: 10px;
  background-color: #171717;
  color: #FFF;
  border: 1.5px solid gray;
}

.image_label {
  width: 34vw;
  border-radius: 5px;
  margin: 5px;
  background-color: white;
  color: black;
}

.checkbox_input {
  height: 3vh;
  width: 3vw;


}

.fieldName1 {
  text-align: center;
  margin-top: 8px;
}

::placeholder {
  color: rgb(160, 156, 156);
  /* font-size: 18px; */
}

.checkbox_input:checked:before {
  background-color: green;
}

/* ::-webkit-calendar-picker-indicator {
  filter: invert(1);
} */
.image_label {
  width: 120px;
  padding: 10px;
}

.option {
  padding: 15px;
}

@media screen and (min-width: 1440px) {
  .image_label {
    width: 36vw;
  }

  .inputField {
    width: 36vw;
  }

  .dropdownInput {
    width: 37vw;
  }

  .multiLineTextBox {

    width: 36vw;
  }
}