.card {
  width: 250px;
  height: 400px;
  border-radius: 20px;
  background-color: white;
  color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
 /* Center the card horizontally */
}

.image {
  width: 100%;
  height: 80%; /* Adjust the height of the image */
  object-fit: cover;
}

.content {
  padding: 20px;
}

.title {
  font-size: 20px;
  margin-bottom: 10px;
  
}

.description {
  font-size: 14px;
  color: #555;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Centers items horizontally */
    gap: 20px; /* Adjust the gap between items */
    overflow: auto;
    height: 86vh;
  }

  
  .slideshowImage {
    width: 100%; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
    max-height: 80vh; /* Limit maximum height */
    object-fit: contain; /* Ensure the entire image fits within the container */
    margin: 0 auto; /* Center the image horizontally */
  }