.main_div{
    background-color: #1e1e1e;
    color: white;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 80vh;
}
.recruiter_table{
    width: 98%;
    /* margin-top: 20px; */
    border-collapse: collapse;
    margin: 18px;
}

.table_td{
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #fff;
    /* border: 0.5px  solid #fff; */
}

.table_heading{
    /* width: 100%; */
    background-color: #383737;
    padding: 8px;

}
.table_row{
    width: 100%;
}
.viewLink{
    color: #fff;
    text-decoration: none;
    font-weight: bold;

}
.viewLink:hover{
    color: crimson;
}
.sideNavComp{
    flex: 1;
}
.linkDiv{
    float: right;
    width: 50px;
    background-color: crimson;
    padding: 8px;
    margin: 10px;
    text-align: center;
    border-radius: 10px;

}
.backlink{
    text-decoration: none;
    color: #fff;
}

.loadingLayout {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.table_add{
    padding: 6px;
    text-align: center;
     border-bottom: 1px solid #fff;
}