.top_div {
    flex: 5;
    display: flex;
  }
  .container {
    flex: 1;
    overflow: auto;
    height: 86vh;
    background-color: #171717;
    margin: 10px;
  }
  .recruiterInfo{
    display: flex;
    flex-direction: row;
  }
  .submitButton {
    font-family: inherit;
    background: #f44336;
    color: white;
    padding: 5px 10px;
    border-radius: 6px;
    cursor: pointer;
    align-self: center;
    height: 30px;
    border: none;
  }
  .CheckBoxHeading{
      padding: 10px;
      font-size: 19px;
  }
  .checkboxposition{
      display: flex;
      padding: 10px;
  }
  .checkbox_input{
      margin: 6px;
  }
  .checkbox{
      display: flex;
      justify-content: space-around;
  }
  .Form_Heading{
      padding: 12px;
      font-size: 18px;
      font-weight: bold;
    
  }
  .form {
    /* width: 60%; */
    border: 1px groove grey;
    color: white;
    margin: auto;
    margin: 10px;
  }
  .form_header {
    font-weight: 600;
    /* background-color: #292929; */
    color: white;
    /* padding: 8px; */
    /* border-radius: 5px; */
    font-size: 22px;
  }
  .img_container {
    flex: 1;
    color: white;
    margin: 10px;
    background-color: #171717;
  }
  .image {
    height: 70vh;
    margin: 10px;
    border: 1px groove grey;
  }
  .Upload_button {
    background-color: #f02f39;
    color: white;
    display: flex;
    width: 100px;
    text-align: center;
    font-size: 22px;
    padding: 5px 14px;
    margin: auto;
    border-radius: 10px;
  }
  .form_input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
  }
  .form_header_container{
    display: flex;
    background-color: #292929;
    padding: 8px;
    justify-content: space-between;
  }
  .btn_button{
    margin-top: 60px;
    margin-left: 12px;
  }
  .formGroup label {
    font-weight: 400;
    font-family: inherit;
    color: white;
  }
  .input {
    display: flex;
    flex-direction: column;
  }
  .fieldName {
    margin: 10px 10px;
    font-size: 18px;
  
  }
  .fields1,
  .fields2 {
    margin: 10px 10px;
  }
  .dropdownInput{
    width: 35vw;
    padding: 10px;
   background-color: #171717;
   color: #FFF;
   border: 1.5px solid gray;
  }
  .citySuggestion{
    margin-left: 20px;
    font-size: 14px;
  }
  .walkInDateTime{
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    justify-content:space-around;
  }
  .inputField{
    width: 150px;
     padding: 10px;
    background-color: #171717;
    color: #FFF;
    border: 1.5px solid gray;
    
  }