.createCompany {
  width: 100%;
  height: 96vh;
  display: grid;
  grid-template-columns: 5fr 5fr;
  overflow:auto;
}
.searchWrapper {
  background-color: #a050db;
  padding: 12px 0;
}
.loadingLayout {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.companySearch {
  display: flex;
  justify-content: center;
}

.companySearch label {
  font-weight: 400;
  font-size: 20px;
  color: white;
}

.companySearch input {
  width: 300px;
  height: 28px;
  border-radius: 5px;
  border: 1px solid;
  margin: 0 10px;
}

.companySearch button {
  width: 80px;
  height: 30px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: white;
  color: rgb(0, 0, 0);
  cursor: pointer;
}
.searchResultBox {
  max-width: 90%;
  height: 500px;
  border: 1px solid gray;
  border-radius: 15px;
  margin: 30px auto;
  overflow: auto;
  /* background-color: #fff; */
  color: #fff;
}

.searchResultBox:hover {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.searchResultHeader {
  background-color: #a050db;
  color: white;
  font-size: 20px;
  padding: 13px 20px;
}
.searchResultHeader h3 {
  margin: 0;
}

.searchItems p {
  border: 1px solid gray;
  padding: 5px;
  margin: 0;
  cursor: pointer;
}

.searchItems p:hover {
  background-color: grey;
}

.rightSide {
  height: 140vh;
  border-left: 2px solid black;
  border-right: 2px solid black;
}

.rightSide h3 {
  background-color: #a050db;
  font-size: 19px;
  color: white;
  text-align: center;
  margin: 0;
  margin-bottom: 30px;
  padding: 15px 0;
}
.companyForm {
  display: flex;
  flex-direction: column;
  align-items: center;
   height: 100vh;
}
.buttonWrapper {
  margin: 34px auto;
  padding: 5px 0;
  display: flex;
  justify-content: center;
}

.cancelButton {
  margin: 0 20px;
}
