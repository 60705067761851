.top_div {
  flex: 5;
  display: flex;
}

.mt2 {
  margin-top: 20px;
}

.container {
  flex: 1;
  overflow: auto;
  height: 86vh;
  background-color: #171717;
  margin: 10px;
}

.form {
  /* width: 60%; */
  border: 1px groove grey;
  color: white;
  margin: auto;
  margin: 10px;
}

.form_header {
  font-weight: 600;
  /* background-color: #292929; */
  color: white;
  /* padding: 8px; */
  /* border-radius: 5px; */
  font-size: 22px;
}

.img_container {
  flex: 1;
  color: white;
  margin: 10px;
  background-color: #171717;
}

.image {
  height: 70vh;
  margin: 10px;
  border: 1px groove grey;
}

.inputField {
  width: 34vw;
  padding: 10px;
  background-color: #171717;
  color: #fff;
  border: 1.5px solid gray;
  display: flex;
  justify-content: space-between;
}

.field1 {
  margin: 10px;
  margin-top: 20px;
}

.Upload_button {
  margin-top: 50px auto;
  background-color: #f02f39;
  color: white;
  display: flex;
  width: 100px;
  text-align: center;
  font-size: 22px;
  padding: 5px 14px;
  border-radius: 5px;
  border: transparent;
  transition: all;
}

.Upload_button:hover {
  background-color: #d31d26;
}

.btnWrapper {
  margin: 20px 0;
}

.form_input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

.form_header_container {
  display: flex;
  background-color: #292929;
  padding: 8px;
  justify-content: space-between;
}

.textEditorWrapper {
  width: 36vw;
  margin: 10px;
}

.AddHrProfessional_Description {
  padding: 12px;
  font-size: 18px;
}

.AddHrProfessional_Desc {
  width: 35vw;
  margin: 10px;
}

.checkbox {
  gap: 10px;
}

.showImage {
  width: 100px;
  height: 100px;
  margin-left: 10px;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
}
.MultipleImgSelect {
  height: 70px;
  width: 70px;
  margin: 8px;
}
.RemoveImgIcon {
  margin-bottom: 70px;
  margin-left: -16px;
  color: white;
  cursor: pointer;
}
.MapImg {
  display: flex;
  flex-wrap: wrap;
}
.ImageUrlContainer {
  display: flex;
}
.CopyURLIcon {
  margin-left: -20px;
  color: #fff;
  cursor: pointer;
  margin-top: 60px;
}
.CopyURLIcon:hover {
  color: #f44336;
}

.formGroup {
  margin: 10px 0;
}
.formGroup label {
  font-weight: 400;
  font-family: inherit;
  color: white;
}
.formGroup input {
  font-family: inherit;
  padding: 2px 5px;
  border-radius: 10px;
  background-color: #1d1b1b;
  color: white;
}
.formGroup textarea {
  padding: 5px;
  font-family: inherit;
  resize: none;
  overflow-y: auto;
  display: block;
  width: 100%;
  border-radius: 10px;
  background-color: #1d1b1b;
  color: white;
}
.formGroup input[name="title"] {
  width: 100%;
  display: block;
  border-radius: 10px;
}
.NewArticle input:focus,
textarea:focus {
  border-color: #007084;
  outline: none;
}
.multipleImageContainer {
  padding: 5px 14px;
}
.gridContainer {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
  /* background-color: #2196f3; */
  gap: 5px;
  /* padding: 10px; */
}
