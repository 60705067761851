.dashboard_users {
  display: flex;
  width: 92%;
  flex-direction: row;
  margin: auto;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
  border: 0.5px groove #171717;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  height: 8vh;
  padding: 0px 14px;
  background-color: #171717;
}
.users_title{
  background-color: #171717;
  border: none;
  color: white;
}
/* .users_title h4{
  font-size: 16px;
} */
.users_title button{
  text-decoration: none!important;

}