.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: fixed;
  align-items: center;
  background-color: #f02f39;
  color: white;
  font-size: 18px;
  font-weight: 500;
  top: 35vh;
  height: 18vh;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 1100;
  animation: Modal_slide-down__1A-ai 300ms ease-out forwards
}

.heading {
  text-align: center;
  padding-bottom: 8px;
}

.button {
  width: 70px;
  color: #f02f39;
  padding: 2px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


@media (min-width: 768px) {
  .modal {
    width: 20rem;
    left: calc(60% - 18rem);
    height: 20vh;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.buttonWrapper {
  display: flex;
  justify-content: space-around;
  gap: 10px;
}