
.jobItem {
  background-color: #d8d4d475;
  width: 100%;
  min-height: 300px;
  display: grid;
  grid-template-columns: 2.0fr 7.7fr;
   margin : 20px 0  0 0px;
   text-decoration: none;
   color: black;
   background-color: #fff;
   line-height: 1.2;
}
.jobItemImage {
  padding: 5px;
  border: 1px solid #bbbbbb;
  margin: auto;
  width: 270px;
    height: 300px;
}
 .jobItemImage img {
  width: 100%;
  height: 100%;
  margin:auto 0;
}
.jobItemInfo {
  /* max-width: 500px; */
  margin-left: 20px;

}

 .jobInfoTitle {
  margin: 10px 0;
  text-decoration: none;
}
.validation {
  color :crimson;
  font-size : 1;
 width: 310px;
  display: flex;
  justify-content: flex-end;
  font-weight: 200;
  font-style: italic;
}
.fetchButton{
  width: 100%;
   display: flex;
   justify-content: flex-end;
}
.searchButton{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  
}
.fetchMeassage{
  color : #fff
  
}