.PivotMainContainer{
    flex: 5;
    background-color: #fff;
    overflow: scroll;
}
.PivotHeadingConatiner{
    display: flex;
    padding: 12px;
}
.PivotdateContainer{
    border: 0.5px solid;
}
.PivotSerialNoContainer{
    border: 0.5px solid;
    width: 10vw;
    text-align: center;
    height: 3.6vh;
}
.PivotPartnerHeadingcontainer{
    border: 0.5px solid;
    width: 100%;
}
.PivotPartnerHeading{
    border: 0.5px solid;
    text-align: center;
}
.PivotPartnerCountContainer{
    display: flex;
}
.PivotCount{
    border: 0.5px solid;
    width: 10vw;
    text-align: center;
    border: 1px solid;
}
.PivotCountDate{
    border: 0.5px solid;
    width: 10vw;
    padding: 12px 0px;
    border: 1px solid;
    text-align: center;
}
.PivotDataContainer{
    display: flex;
}