.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}
.btnun {
  border: none;
  color: #ffffff;
  background-color: #af4444;
  border-radius: 5px;
  padding: 3px 10px;
}
.btnapp {
  border: none;
  color: #ffffff;
  background-color: #6a8547;
  border-radius: 5px;
  padding: 3px 10px;
}
.orders_table {
  width: 100%;
  border-collapse: collapse;
  margin: 17px;
}
.table_heading {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  background-color: #383737;
  padding: 8px;
}
.table_td {
  text-align: center;
  padding: 6px;
  font-size: 13px;
  background-color: #171717;
  border-bottom: 0.5px solid #292929;
  /* display: flex; */
  width: 100%;
  border-left: 0.5px solid #292929;
}
.table_td_link {
  color: #f02f39;
}
.table_container {
  border-radius: 5px;
  width: 100%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  margin: auto;
}
.answe {
  margin: 10px auto;
  display: block;
  text-align: center;
}

.modal {
  display: flex;
  flex-direction: column;

  position: fixed;
  background-color: #171717;
  color: white;
  font-size: 18px;
  font-weight: 500;
  top: 5vh;

  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 30;
  animation: Modal_slide-down__1A-ai 300ms ease-out forwards;
}

.button {
  width: 70px;
  color: #f02f39;
  padding: 2px;
  border: none;
  text-align: center;
  display: block;
  margin: 10px auto;
  border-radius: 4px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
