.dashboard_users_open {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
  }
  .dashboard_users_container {
    width: 92%;
    margin: 20px auto;
    border: 0.5px groove #171717;
    border-radius: 5px;
    padding: 0px 14px;
    background-color: #171717;
  }
  
  .card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .dashboard_title {
    font-weight: 600;
    background-color: #292929;
    color: white;
    padding: 5px 0px 0px 12px;
    border-radius: 5px;
    font-size: 16px;
    height: 36px;
    margin-top: 0;
  }
  .table_container {
    margin: 20px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    background-color: #1e1e1e;
    border-radius: 5px;
  }
  .dashborad_table {
    width: 100%;
    margin-top: 10px;
    border-collapse: collapse;
  }
  .table_heading {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    padding: 5px;
    border-bottom: 0.5px groove grey;
  }
  .table_td {
    text-align: center;
    padding: 10px;
    font-size: 16px;
  }
  .spinner{
    text-align: center;
   }
   .users_button{
    background-color: #171717;
    border: none;
    color: white;
  }
  .LinkCard{
    text-decoration: none;
    color: #fff;
  }