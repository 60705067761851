.dashboard_container {
  flex: 5;
  height: 86vh;
  margin-top: 20px;
  overflow: auto;
  color: white;
}
.arrow {
  font-size: 16px;
  margin-left: 5px;
  cursor: pointer;
}
.events_table {
  border-collapse: collapse;
  margin: 10px;
  margin-top: 0px;
}
.table_heading {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  background-color: #383737;
  padding: 4px;
}
.table_td {
  text-align: center;
  padding: 6px;
  width: 20px;
  background-color: #292929;
  border-bottom: 1px groove #888888;
}

.table_container {
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  margin: auto;
}
.container {
  display: flex;
  flex-direction: column;
}
.events_title {
  background-color: #171717;
  padding: 5px 0px 0px 12px;
  border-radius: 5px;
  font-size: 20px;
  height: 35px;
  margin: 10px;
  margin-bottom: 0px;
}
.button_wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 0px 10px;
}
.Add_event_button {
  background-color: #f02f39;
  color: #fff;
  font-size: 18px;
  border: none;
  text-align: center;
  padding: 3px;
  width: 10vw;
  border-radius: 6px;
}
.Add_event_button:hover {
  background-color: #fff;
  color: #f02f39;
}
.table_td_image {
  text-align: center;
  /* padding: 8px; */
  width: 50px;
  height: 50px;
  background-color: #724040;
  border-radius: 50%;
}
.form_input {
  display: flex;
}
.spinner {
  text-align: center;
  padding-top: 20px;
}
.table_td_Link {
  text-decoration: none !important;
  color: rgb(255, 251, 251);
}
.table_td_Link:hover {
  color: rgb(238, 26, 26);
}
.button_container {
  display: flex;
  width: 10vw;
  margin: 25px;
  justify-content: space-between;
}
.button_articleList {
  padding: 5px;
  width: 65px;
  cursor: pointer;
}
.dateDiv {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 60%;
}
.dateLable {
  margin: 0px 20px 0px 20px;
}
.DateFilter_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerPageNavigation {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  
}
