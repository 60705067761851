.mainDiv{
     width: 100%;
      background-color: #1e1e1e;
      color: #fff;
      height: 100vh;
}
.tableHeader{
    background-color: #383737;
    padding: 8px;
}
.tableMain{
    border-collapse: collapse;
}
.tableData{
    padding: 10px;
    text-align: center;
    border-bottom: 0.5px solid rgb(175, 173, 173);
}
.loadingLayout {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.tableDate{
    width: 200px;
    text-align: center;
    border-bottom:  0.5px solid rgb(175, 173, 173);

}
.tableDesc{
    width: 350px;
    text-align: center;
    border-bottom:  0.5px solid rgb(175, 173, 173);
    margin-left: 10px;
}
