.flexContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .flexContainer > div {
  padding-top:10px;
  }
  .paginateCtn{
      display: flex;
  }
  .roundEffect{
    color:crimson;
    cursor:pointer;
  /*   border:1px solid red; */
    
    font-size:16px;
    text-align:center;
    padding-left:10px;
    padding-right:10px;
    padding-top:9px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin:5px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
     text-decoration: none;
  }
  
  .roundEffect:hover{
    text-decoration: none;
    background:crimson;
    color:#FFF;
  }
  .roundEffect:hover a {
    text-decoration: none;
    color:#FFF;
  }
  
  .active{
    background:crimson;
    color:#FFF;
  }
  .paginateCtn a:link {
    text-decoration: none;
    color:crimson;
  }
  