.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}
.ModalStyle {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 50px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  border-radius: 20px;
}
.ModalStyle button {
  background-color: #f02f39;
  padding: 5px 10px;
  border: none;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  margin-top: 20px;
}
.submitionGIF {
  width: 30%;
}
