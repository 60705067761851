.card {
  /* line-height: 3px; */
  display: flex;
  justify-content: space-around;
  width: 17vw;
  margin: 20px 10px;
  padding: 15px 0px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  background-color: #292929;
  border-radius: 5px;
}
