.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .modal {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: fixed;
    align-items: center;
    background-color: #f02f39;
    color: white;
    font-size: 18px;
    font-weight: 500;
    top: 25vh; /* Adjusted for better spacing */
    height: auto; /* Changed to accommodate dynamic content */
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
    z-index: 1100;
    animation: slide-down 300ms ease-out forwards;
    width: 90%; /* Responsive sizing */
  }
  
  .heading {
    text-align: center;
    padding-bottom: 8px;
    font-size: 20px;
  }
  
  .textarea {
    width: 288px; /* Full width within modal */
    height: 80px; /* Enough space for remarks */
    margin: 10px 0;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    font-family: inherit;
    resize: none; /* Prevent resizing for consistent layout */
  }
  
  .textarea:focus {
    outline: none;
    border-color: #f02f39; /* Highlight with modal's theme color */
    box-shadow: 0 0 4px #f02f39;
  }
  
  .button {
    width: 70px;
    color: #f02f39;
    padding: 2px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: white;
    transition: background-color 0.2s, color 0.2s;
  }
  
  .button:hover {
    background-color: #f02f39;
    color: white;
  }
  
  .buttonWrapper {
    display: flex;
    justify-content: space-around;
    gap: 10px;
  }
  
  @media (min-width: 768px) {
    .modal {
      width: 20rem;
      left: calc(50% - 10rem); /* Center horizontally */
      height: auto; /* Adjust height dynamically */
    }
  
    .textarea {
      height: 100px; /* Slightly larger for desktop view */
    }
  }
  
  @keyframes slide-down {
    from {
      opacity: 0;
      transform: translateY(-3rem);
    }
  
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  