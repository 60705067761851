.top_div {
  flex: 5;
  display: flex;
}
.container {
  flex: 1;
  overflow: auto;
  height: 86vh;
  background-color: #171717;
  margin: 10px;
}
.form {
  border: 1px groove grey;
  color: white;
  margin: auto;
  margin: 10px;
}
.form_header {
  font-weight: 600;
  color: white;
  font-size: 22px;
}
.img_container {
  flex: 1;
  color: white;
  margin: 10px;
  background-color: #171717;
}
.image {
  height: 70vh;
  margin: 10px;
  border: 1px groove grey;
}
.Upload_button {
  background-color: #f02f39;
  color: white;
  display: flex;
  width: 100px;
  text-align: center;
  font-size: 22px;
  padding: 5px 14px;
  margin: auto;
  border-radius: 10px;
}
.form_input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}
.inputField {
  width: 34vw;
  padding: 10px;
  background-color: #171717;
  color: #fff;
  border: 1.5px solid gray;
  display: flex;
  justify-content: space-between;
}
.fieldName {
  margin-top: 10px;
  margin-left: 10px;
  font-size: 18px;
}
.field1 {
  margin: 10px;
  margin-top: 20px;
}
.input_button {
  margin-left: 5px;
  width: 40px;
  text-align: center;
}
.inputFieldText {
  width: 30vw;
}
.event_image {
  width: 35%;
  margin: 10px;
  height: 10vh;
}
.form_header_container {
  display: flex;
  background-color: #292929;
  padding: 8px;
  justify-content: space-between;
}
.fieldName {
  margin: 10px 10px;
  font-size: 18px;
}
.inputFieldId{
  width: 34vw;
   padding: 12px;
  background-color: #171717;
  color: #FFF;
  border: 1.5px solid gray;
  height: 7vh;
}
.fields1 {
  margin: 10px 10px;
}
.fieldsPaid{
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  flex-direction: row-reverse;
}
.searchCompany{
  position: relative;
}
.dropDownContainer{
  position: absolute;
  left: 0;
  z-index: 1;
  border: 1px solid white;
  border-top: 0;
  max-height: 200px;
  width: 100%;
 
  overflow-y: auto;
  background-color: #171717;
  padding: 10px;
}
.dropDownNoValue{
  position: absolute;
  left: 0;
}
.dropDownOption{
  cursor: pointer;
}
.dropDownOption:hover{
  background-color: white;
  color: black;
  transition: all ease-in-out 0.3s;
}