.top_div {
    flex: 5;
    display: flex;
}
.successMessage {
    width: 100%;
    margin-left: 50px;
  color: rgb(7, 182, 7);
}

.errorMessage {
  color: red;
}

.required {
    color: red;
    margin-left: 5px;
}

.mt2 {
    margin-top: 20px;
}

.imgicon {
    width: 80px;

    margin-left: 20px;
}

.container {
    flex: 1;
    overflow: auto;
    height: 86vh;
    background-color: #171717;
    margin: 10px;
}

.form {
    /* width: 60%; */
    border: 1px groove grey;
    color: white;
    margin: auto;
    margin: 10px;
}

.form_header {
    font-weight: 600;
    /* background-color: #292929; */
    color: white;
    /* padding: 8px; */
    /* border-radius: 5px; */
    font-size: 22px;
}

.img_container {
    flex: 1;
    color: white;
    margin: 10px;
    background-color: #171717;
}

.image {
    height: 70vh;
    margin: 10px;
    border: 1px groove grey;
}

.inputField {
    width: 34vw;
    padding: 10px;
    background-color: #171717;
    color: #fff;
    border: 1.5px solid gray;
    display: flex;
    justify-content: space-between;
}

.field1 {
    margin: 10px;
    margin-top: 20px;
}

.Upload_button {
    margin-top: 50px auto;
    background-color: #f02f39;
    color: white;
    display: flex;
    width: 100px;
    text-align: center;
    font-size: 22px;
    padding: 5px 14px;
    border-radius: 5px;
    border: transparent;
    transition: all;
}

.Upload_button:hover {
    background-color: #d31d26;
}

.btnWrapper {
   
    display: flex; /* Enable flexbox */
    align-items: flex-end; /* Align items to the bottom */
    justify-content: center; /* Center items horizontally */
    padding-bottom: 20px; /* Add some spacing from the bottom if needed */
}

.form_input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
}

.form_header_container {
    display: flex;
    background-color: #292929;
    padding: 8px;
    justify-content: space-between;
}

.textEditorWrapper {
    width: 36vw;
    margin: 10px;
}

.AddHrProfessional_Description {
    padding: 12px;
    font-size: 18px;
}

.AddHrProfessional_Desc {
    width: 35vw;
    margin: 10px;
}

.checkbox {
    gap: 10px;
}

.showImage {
    width: 100px;
    height: 100px;
    margin-left: 10px;
}

.loader {
    position: fixed;
    top: 50%;
    left: 50%;
}

.MultipleImgSelect {
    height: 70px;
    width: 70px;
    margin: 8px;
}

.RemoveImgIcon {
    margin-bottom: 70px;
    margin-left: -16px;
    color: white;
    cursor: pointer;
}

.MapImg {
    display: flex;
    flex-wrap: wrap;
}

.ImageUrlContainer {
    display: flex;
}

.CopyURLIcon {
    margin-left: -20px;
    color: #fff;
    cursor: pointer;
    margin-top: 60px;
}

.CopyURLIcon:hover {
    color: #f44336;
}

.formGroup {
    margin: 0px;
}

.formGroup label {
    font-weight: 400;
    font-family: inherit;
    color: white;
}

.formGroup input {
    font-family: inherit;
    padding: 5px 5px;
    border-radius: 10px;
    background-color: #1d1b1b;
    color: white;
}

.formGroup textarea {
    padding: 5px;
    font-family: inherit;
    resize: none;
    overflow-y: auto;
    display: block;
    width: 100%;
    border-radius: 10px;
    background-color: #1d1b1b;
    color: white;
}

.formGroup input[name="title"] {
    width: 100%;
    display: block;
    border-radius: 10px;
}

.NewArticle input:focus,
textarea:focus {
    border-color: #007084;
    outline: none;
}

.multipleImageContainer {
    padding: 5px 14px;
}

.gridContainer {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
    /* background-color: #2196f3; */
    gap: 5px;
    /* padding: 10px; */
}

.fieldName {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 18px;
    /* width: 18vw; */
}

.field1 {
    margin: 10px;
    margin-top: 20px;
}

.input_button {
    margin-left: 5px;
    width: 40px;
    text-align: center;
}

.inputFieldText {
    width: 30vw;
}

.imgicon {
    width: 80px;
    margin-left: 20px;
}

.contentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #ff4d4d;
}

.contentHeader h3 {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    color: red;
}

.ImgContainer {
    display: flex;
    flex-direction: column;
}

.removeContentBtn {
    background-color: transparent;
    border: none;
    color: #ff4d4d;
    cursor: pointer;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.removeContentBtn:hover {
    color: #ff1a1a;
}

.pan_card_section {
    margin-top: 20px;
  }
  
  .pan_card_label {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .pan_card_container {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .pan_card_image {
    max-width: 200px;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .download_button {
    background-color: #007bff;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    text-decoration: none;
  }
  
  .download_button:hover {
    background-color: #0056b3;
  }