.eventdetail_container {
  flex: 5;
  height: 86vh;
  margin: 20px;
  overflow: auto;
}
.eventdetail_main {
  display: flex;
  flex-direction: column;
  background-color: #171717;
  color: rgb(197, 195, 195);
  /* height: 86vh; */
  padding: 10px;
  width: 100%;
}
.event_image {
  width: 35vw;
  margin: 10px;
  height: 50vh;
}
.event_detail {
  display: flex;
  margin: auto;
}
.event_text {
  margin: 10px;
}
.table_th,
.table_td {
  font-size: 20px;
  padding: 12px;
}
.detail_bottom {
  margin: 10px;
}
.Time_date {
  display: flex;
  justify-content: space-around;
}
.date {
  font-weight: 500;
}
.description,
.date_text {
  color: rgb(100, 98, 98);
}
.BackButton{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 8px;
}
