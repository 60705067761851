.InterstitialAds_container {
  margin: 40px 0px;
  flex: 5;
  height: 100vh;
  overflow: auto;
  color: white;
}
.Ads_container {
  margin: 10px;
  display: flex;
  flex-direction: row;
  width: 90%;
  height: auto;
  margin: auto;
}
.Ads_image {
  width: 45%;
  display: flex;
  flex-direction: column;
}
.image {
  margin: auto;
  width: 70%;
  margin-top: 10px;
}
.button {
  color: #f02f39;
  font-size: 22px;
  border: none;
  width: 24%;
  text-align: center;
  padding: 7px;
  margin: auto;
  border-radius: 10px;
  margin-top: 20px;
}
.button:hover {
  background-color: #f02f39;
  color: #fff;
}
.date_container {
  background-color: #171717;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%);
  width: 44%;
  height: auto;
  margin: 10px;
  text-align: center;
  padding: 38px 0px;
  border-top: 4px solid #f02f39;
  border-radius: 5px;
}

.date_field {
  text-align: center;
}
.label {
  font-size: 20px;
}
.date_picker {
  width: 12vw;
  height: 4vh;
  font-size: 20px;
  text-align: center;
  margin: 20px;
  border-radius: 12px;
}
.set_time {
  height: 10vh;

  text-align: center;
}

.heading {
  font-size: 30px;
}
.Upload_button {
  background-color: #f02f39;
  color: white;
  font-size: 22px;
  border: none;
  width: 24%;
  text-align: center;
  padding: 7px;
  margin: auto;
  border-radius: 10px;
  margin-top: 20px;
}
@media screen and (max-width: 1224px) {
  .Upload_button {
    margin-top: 0px;
  }
  .date_picker {
    font-size: 16px;
  }
}
