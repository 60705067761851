.logo {
  height: auto;
  width: 160px;
}
.logoutBtn {
  padding: 5px 20px;
  border-radius: 10px;
}
.logoutBtn:hover {
  background-color: rgb(195, 193, 193);
}
.icon {
  height: 6vh;
}
.header {
  /* height: 11vh; */
  display: flex;
  flex-direction: row;
  padding: 18px;
  justify-content: space-between;
  align-items: center;
  background-color: #171717;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  position: sticky;
}
.header_logo {
  display: flex;
  width: 21%;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 425px) {
  .icon {
    height: 7vh;
  }
  .header_logo {
    width: 70%;
  }
  .header {
    padding: 22px;
    height: 5rem;
  }
}
