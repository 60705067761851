.container {
  display: flex;
}
.main_container {
  height: 80px;
}
.mob_view {
  display: none;
}

@media screen and (max-width: 1002px) {
  .web_view {
    display: none;
  }
  .mob_view {
    display: flex;
    width: 100%;
    height: 50vh;
  }
  .mobView_text {
    margin: auto;
    color: #f02f39;
  }
}
.w_100 {
  width: 100%;
}
