.main_container{
    background-color: #1e1e1e;
    width: 100%;
     height: 100vh;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    overflow-x: auto;
}

.recruiterDetails h2 {
    color: rgb(218, 218, 218);
    display: flex;
    justify-content: center;
}

h4{
    color: rgb(150, 148, 148);
}

.recruitersData{
    margin-left: 10px;
    margin-right: 12px;
    width: 50%;
}

.recruiterDetails{
   
    margin-left: 10px;
  
}

.jobDetails{
    margin-top: 10px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: right;
    margin-left: 50px;    
}


.recruitersData{
    align-items: center;
    margin-top: 53px;
}

.tableHeads{
    color: #FFF;
    padding: 8px;  
}

.tableDiv{
    height: auto;
    border-left: 0.5px solid #fff;
}

.tableHead{
    background-color: #383737;

}

.tableMain{
    border-collapse: collapse;
    margin: 10px;
}

.tableDetails{
    color: rgb(150, 148, 148);
    padding: 8px;
    border-bottom: 0.5px solid rgb(150, 148, 148);
    text-align: center;
}

.linkDiv{
    display: flex;
    justify-content: center;
    padding: 20px;
}

.link{
    width: 100px;
    padding: 10px;
    text-decoration: none;
    color: #FFF;
    text-align: center;
    background-color: #f02f39;
    margin: 0 10px;
}
.loadingLayout {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }