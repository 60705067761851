.top_div {
  flex: 5;
  display: flex;
}

.container {
  flex: 1;
  overflow: auto;
  height: 86vh;
  background-color: #171717;
  margin: 10px;
}

.form {
  /* width: 60%; */
  border: 1px groove grey;
  color: white;
  margin: auto;
  margin: 10px;
}

.form_header {
  font-weight: 600;
  /* background-color: #292929; */
  color: white;
  /* padding: 8px; */
  /* border-radius: 5px; */
  font-size: 22px;
}

.img_container {
  flex: 1;
  color: white;
  margin: 10px;
  background-color: #171717;
}

.image {
  height: 70vh;
  margin: 10px;
  border: 1px groove grey;
}

.Upload_button {
  background-color: #f02f39;
  color: white;
  display: flex;
  width: 100px;
  text-align: center;
  font-size: 22px;
  padding: 5px 14px;
  margin: auto;
  border-radius: 10px;
}

.form_input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

.inputField {
  width: 34vw;
  padding: 10px;
  background-color: #171717;
  color: #fff;
  border: 1.5px solid gray;
  display: flex;
  justify-content: space-between;
}

.fieldName {
  margin-top: 10px;
  margin-left: 10px;
  font-size: 18px;
  /* width: 18vw; */
}

.field1 {
  margin: 10px;
  margin-top: 20px;
}

.input_button {
  margin-left: 5px;
  width: 40px;
  text-align: center;
}

.inputFieldText {
  width: 30vw;
}

.event_image {
  width: 35%;
  margin: 10px;
  height: 10vh;
}

.form_header_container {
  display: flex;
  background-color: #292929;
  padding: 8px;
  justify-content: space-between;
}

.textEditorWrapper {
  width: 36vw;
  margin: 10px;
}

.AddHrProfessional_Description {
  padding: 12px;
  font-size: 18px;
}

.AddHrProfessional_Desc {
  width: 35vw;
  margin: 10px;
}

.TooltipIcon {
  margin-left: 5px;
  cursor: pointer;
  color: #007bff;
}

.TooltipBox {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #333;
  z-index: 1000;
  top: 35px;
  /* Adjust based on label height */
  width: 250px;
  margin-left: 20px;
}

/* Validation tooltip styling */
.ValidationTooltip {
  position: absolute;
  top: -3%;
  left: 20%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #333;
  z-index: 1000;
  width: 300px;
  margin-top: 5px;
}

.successMessage {
  color: rgb(7, 182, 7);
}

.errorMessage {
  color: red;
}

.required {
  color: #f02939 !important;
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
}