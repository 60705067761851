.navbar {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.mainContainer {
  width: 100%;
  height: 86vh;
  overflow: auto;
}
.headerJobs {
  width: 80%;
}
.navbar a {
  padding: 12px;
  color: white;
  font-size: 17px;
  width: 20%;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  /* background-color: #a050db;
  border-bottom: 2px solid rgba(0, 0, 0, 0); */
}

.navbar a:hover {
  background-color: white;
  color: black;
  /* border-radius: 10px;
  border-bottom: 2px solid #a050db; */
}
a.activeNav {
  background-color: white;
  color: black;
  /* border-radius: 10px;
  border-bottom: 2px solid #a050db; */
}

.fieldName label {
  float: left;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  margin-top: 4px;
  color: #fff;
  /* width: 30%; */
}

.fields3 select {
  width: 306px;
  margin-top: 10px;
}
.inputField {
  width: 300px;
}

.fields2 textarea {
  resize: none;
  overflow-y: scroll;
}
.bgHeading {
  width: "100%";
  background-color: #a050db;
  padding: 10px;
}

.headingText {
  color: white;
  font-weight: bold;
}
.simpleHeading {
  color: #808080;
  font-style: bold;
  font-weight: 500;
}
.fieldName {
  font-weight: bold;
  width: 170px;
}

.fields1,
.fields2 {
  margin-left: 170px;
  margin-top: 10px;
}

.fields3 {
  margin-left: 170px;
}

.multiLineTextBox {
  font-family: inherit;
  width: 300px;
}
.themeButton {
  background-color: #a050db;
  font-size: 14px;
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 5px;
}
.themeButton:hover {
  background-color: white;
  color: #a050db;
  border-width: 1px;
  border-style: solid;
  border-color: #a050db;
}

.experience {
  flex-direction: row;
  display: flex;
}
.walkInDetails {
  display: flex;
  flex-direction: row;
}
.checkbox {
  display: flex;
  width: 500px;
  margin: 10px 0;
  padding-left: 20px;
  color: #fff;
}

.checkbox label {
  font-weight: bold;
}
.checkbox input {
  margin-left: 20px;
}
.dateInput {
  float: right;
  margin: 10px;
}
.dateInput {
  outline: none;
}
.message {
  height: 65vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.icon {
  font-size: 20px;
  margin: 4px;
}
.mainDiv {
  overflow-x: hidden;
}
.mandatoryIcon {
  color: crimson;
  margin-left: 5px;
}
.searchButton {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.dateLable {
  color: #fff;
  margin: 10px;
}
.dateDiv {
  margin: 10px;
  display: flex;
  justify-content: flex-end;
  height: 25px;
  align-items: center;
}
.navbarSub {
  display: flex;

  width: 100%;
}
.navbarSub a {
  padding: 12px;
  color: white;
  font-size: 17px;
  width: 35%;
  text-align: center;
  text-decoration: none;
  background-color: #a050db;
}

.navbarSub a:hover {
  background-color: white;
  color: black;
  border-radius: 10px;
  border-bottom: 2px solid #a050db;
}

.navbarSub a.activeNav {
  background-color: white;
  color: black;
  border-radius: 10px;
  border-bottom: 2px solid #a050db;
}
