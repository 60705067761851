/* Overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
}

/* Modal Container */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 900px;
    background: linear-gradient(135deg, #f6f6f6, #eaeaea);
    border-radius: 15px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
    overflow-y: auto;
    max-height: 90vh;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
}

/* Modal Header */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background: linear-gradient(135deg, #817f7f, #000000);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.headingCustom {
    font-size: 18px;
    font-weight: 500;
    color: rgb(202, 3, 3);
    border-bottom: 2px solid beige;
    padding: 3px 0px;
}

.headingCustomContent {
    font-size: 18px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    padding: 8px 0px;
}

.contentdiv {
    border-bottom: 2px dashed rgb(218, 144, 144)
}

.header h2 {
    margin: 0;
    font-size: 22px;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

/* Close Button */
.closeButton {
    background: none;
    border: none;
    font-size: 26px;
    cursor: pointer;
    color: #fff;
    transition: color 0.3s ease;
}

.closeButton:hover {
    color: #ddd;
}

/* Content Wrapper */
.contentWrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Section Styling */
.section {
    background: #ffffff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: none;
    /* Removed box-shadow */
    border-bottom: 1px solid #f0f0f0;
    /* Subtle divider */
}

/* Image Styling */
.imageContainer {
    display: flex;
    justify-content: center;
    margin: 15px 0;
}

.image {
    width: 400px;
    height: 200px;
    object-fit: cover;
    border: 4px solid #f7797d;
    box-shadow: 0 8px 20px rgba(247, 121, 125, 0.3);
}

/* Table Styling */
.detailsTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 8px;
    /* Remove separation */
}

.detailsTable th,
.detailsTable td {
    padding: 10px 12px;
    font-size: 16px;
    text-align: left;
    border: none;
    /* Remove borders */
}

/* Styling for Table Headers */
.detailsTable th {
    background: none;
    /* No background */
    color: #555;
    font-weight: 600;
    padding: 8px 0px;
}

/* Styling for Table Data */
.detailsTable td {
    background: none;
    /* No background */
    color: #222;
    font-weight: 400;
    padding: 0px 0 0 0;
    /* Only top padding */
}

.detailsTable tr:nth-child(even) th,
.detailsTable tr:nth-child(even) td {
    background: none;
    /* Remove alternating row color */
}

.detailsTable td+td {
    padding-left: 30px;
    /* Add spacing between table cells */
}

/* Footer */
.footer {
    padding: 15px 20px;
    text-align: center;
    border-top: 1px solid #f0f0f0;
    /* Subtle divider */
    background: #f7f7f7;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.closeFooterButton {
    padding: 8px 20px;
    font-size: 14px;
    border-radius: 20px;
    border: 2px solid #f51921;
    background-color: #fff;
    color: #e6232a;
    font-weight: 600;
    transition: all 0.3s ease;
    cursor: pointer;
}

.closeFooterButton:hover {
    background-color: #eb0f16;
    color: #fff;
    border-color: #e70f16;
}

/* Responsive Design */
@media (max-width: 768px) {
    .modal {
        width: 95%;
    }

    .image {
        width: 120px;
        height: 120px;
    }

    .detailsTable th,
    .detailsTable td {
        font-size: 12px;
        padding: 6px 8px;
    }

    .closeFooterButton {
        padding: 6px 16px;
    }
}

.description {
    margin-left: 20px;
}