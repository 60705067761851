.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.ModalStyle {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  /* Added padding for better spacing */
  z-index: 1000;
  display: flex;
  height: 86vh;
  flex-direction: column;
  align-items: center;
  width: 65%;
  border-radius: 20px;
  color: black;
  /* Ensure text color is black */
}

.ModalStyle button {
  background-color: #f02f39;
  padding: 5px 10px;
  border: none;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  margin-top: 20px;
}

.eventdetail_container {
  flex: 5;
  height: 86vh;
  margin: 20px;
  width: 100%;
  overflow: auto;
  padding: 20px;
}

.eventdetail_main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.event_detail {
  display: flex;
  margin: auto;
}

.question_section {
  margin: 10px;
}

.table_th {
  white-space: nowrap;
  display: flex;
  justify-content: start;
  align-items: start;
}

.table_td {
  margin-left: 10px;
  /* Added margin for better spacing */
}

.options_list {
  list-style-type: none;
  padding: 0;
}

.BackButton {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 8px;
}

h4 {
  color: black;
  font-size: 20px;
}