.dashboard_container {
  flex: 5;
  height: 86vh;
  margin-top: 20px;
  overflow: auto;
  color: white;
}
.spinner{
  text-align: center;
 }

