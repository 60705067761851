.header {
  padding: 20px;
  display: flex;
  align-items: center;
}
.listHeader {
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  /* width: 70%; */
}

.listHeader a {
  text-decoration: none !important;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  color: #fff;
  background-color: #f02939;
  border: 1px solid #f02939;
  transition: all 0.3s ease;
}
.listHeader a:hover,
.active {
  color: #f02939 !important;
  background-color: #fff !important;
  border: 1px solid #fff !important;
}
