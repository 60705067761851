/* main section */
.jobAds {
    width: 100%;
    height: 86vh;
    display: grid;
    overflow: hidden;
    grid-template-columns: 6fr 4fr;

  }
    .jobAdsImageField {
      border: 10px solid #b8b4b4;
      border-radius: 10px;
      padding: 30px;
      padding-top: 10px;
    }
    
    .jobAdsIcons {
      display: flex;
      justify-content: flex-end;
    }
    .icon {
      margin: 0 10px;
      font-size: 20px;
    }
    
    .jobAdsImage {
      border: 5px solid black;
      border-radius: 5px;
     width: 90%;
      height: 70vh;
      margin: 20px auto;
      margin-top: 10px;
    }
    .jobAdsImage img {
      width: 100%;
      height: 100%;
    }
  
  /* input field */
  .JobAdsEntry{
    width: 100%;
    height: 86vh;
    overflow-x: hidden;
    /* overflow: auto; */
    box-sizing: border-box;
    border-radius: 10px;
  }
  
  .adEntry {
    border: 1px solid #000000;
    padding: 29px 0;
    padding-bottom: 20px;
    border-top: 0spx;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 81vh; */
    
  }
  
  .buttonDiv{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  }
  .text{
  font-size: 13px;
  font-style: italic;
  margin-left: 100px;
  color: #fff;
  }
  .walkInInterviewButtons{
    display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 10px;
  }
  
  /* location mapper */
  .locationBoxWrapper{
    display: flex;
    flex-wrap: wrap;
  }
  
  .locationBox {
    padding: 5px;
    background-color: #735297;
    color: white;
    margin: 8px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 8px;
  }
  
  .loadingLayout{
    margin: auto;
  
  }
  .closeIcon {
    position: absolute;
    top: 0;
    right: 0;
    width: 12px;
    border-radius: 20px;
    cursor: pointer;
  }
 
  .title{
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    padding: 8px;
    border-radius: 10px;
  }

  .mandatory{
    margin: 0;
   /* margin-left: 100px; */
   color: red;
  }