.articleContainer {
    background-color: white;
    height: 100vh;
    overflow: auto;
}

.gridContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
}

.sidebar {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
}

@media (min-width: 768px) {
    .gridContainer {
        max-width: 800px;
    }

    .sidebar {
        position: sticky;
        top: 0;
        width: auto;
    }
}



.articleHeader {
    background: linear-gradient(to bottom, #C186FC, #fff);
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: 60px;
    max-width: 800px;
}

.articleMain {
    margin-top: -48px;
    padding-bottom: 8px;
    position: relative;
    border-radius: 10px 10px 0 0;
    max-width: 800px;
}

.articleContentContainer {
    margin: 0;
    width: 100%;
    padding: 0 16px;
}

.articleHeader {
    width: 100%;
}

.articleMain {
    width: 100%;
}

@media (min-width: 768px) {

    .articleContentContainer,
    .articleHeader,
    .articleMain {
        max-width: 800px;
    }
}

.articleContent {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.articleProfileOverview {
    overflow: hidden;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px 10px 0 0;
}

.articleDetails {
    background: white;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
}

.articleTitleSection {
    margin-bottom: 16px;
}

.articleTitle {
    font-size: 1.5rem;
    font-weight: bold;
    word-break: break-word;
}

.articleShortDescription {
    font-size: 1rem;
}

.articleShare {
    margin-top: 16px;
}

.articleAuthorInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    color: #6b7280;
    margin-top: 16px;
    text-decoration: none;
}

.authorLink {
    color: #6b7280;
    margin-right: 32px;
}

.authorLink:hover {
    color: #9b59b6;
}

.authorLinkDisabled {
    pointer-events: none;
    color: black;
    text-decoration: none;
    margin: 16px 0px;
}

.articleContentHtml {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
}

.articleDetail {
    font-size: 1rem;
    color: black;
    word-break: break-word;
}

.articleManagerList {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
}

.managerList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.articleDisclaimer {
    padding: 16px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 16px;
}

.disclaimerTitle {
    font-size: 1rem;
    font-weight: medium;
    color: #1f2937;
}

.disclaimerText {
    margin-top: 8px;
    font-size: 0.875rem;
    color: #1f2937;
}

.adSection {
    margin-top: 24px;
}

.googleAd {
    display: flex;
    flex-direction: column;
}

.desktopAd {
    display: none;
}

.mobileAd {
    display: block;
}

@media (min-width: 768px) {
    .desktopAd {
        display: block;
    }

    .mobileAd {
        display: none;
    }
}

.relatedArticleSection {
    margin-top: 16px;
}

.breadcrumbNav {
    display: flex;
    margin: 0 16px;
}

@media (min-width: 768px) {
    .breadcrumbNav {
        margin: 0 90px;
    }
}

.breadcrumbList {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 0.1rem;
}

.breadcrumbHomeLink {
    text-decoration: none;
    color: inherit;
}

.breadcrumbHomeLink.gray {
    color: #9CA3AF;
    /* Gray color */
}

.breadcrumbHomeLink.gray:hover {
    color: #6B7280;
    /* Darker gray on hover */
}

.breadcrumbHomeLink.white {
    color: white;
}

.breadcrumbHomeLink.white:hover {
    color: #D1D5DB;
    /* Lighter gray on hover */
}

.icon {
    height: 1.25rem;
    /* Equivalent to h-5 */
    width: 1.25rem;
    /* Equivalent to w-5 */
    flex-shrink: 0;
}

.breadcrumbItem {
    display: flex;
    align-items: center;
    margin: 10px;
}

.breadcrumbItem .icon.gray {
    color: #9CA3AF;
    /* Gray color */
}

.breadcrumbItem .icon.white {
    color: white;
}

.breadcrumbLink {
    margin-left: 0.25rem;
    /* Equivalent to ml-1 */
    font-size: 0.875rem;
    /* Equivalent to text-sm */
    font-weight: 500;
    /* Equivalent to font-medium */
    text-decoration: none;
    color: inherit;
    display: -webkit-box;
    /* Equivalent to line-clamp-1 */
    -webkit-box-orient: vertical;
    /* Equivalent to line-clamp-1 */
    -webkit-line-clamp: 1;
    /* Equivalent to line-clamp-1 */
    overflow: hidden;
    /* Equivalent to line-clamp-1 */
}

.breadcrumbLink.gray {
    color: #6B7280;
    /* Gray color */
}

.breadcrumbLink.gray:hover {
    color: #374151;
    /* Darker gray on hover */
}

.breadcrumbLink.white {
    color: white;
}

.breadcrumbLink.white:hover {
    color: #D1D5DB;
    /* Lighter gray on hover */
}

.widgetContainer {
    height: 250px;
    width: 350px;
    background-color: white;
    border: 1px solid #e5e7eb;
    /* border-gray-200 */
    border-radius: 0.5rem;
    /* rounded-lg */
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
    /* shadow-md */
}

.widgetHeader {
    background-color: #e9d5ff;
    /* bg-purple-200 */
}

.imageContainer {
    height: 5rem;
    /* h-20 */
    margin: auto;
    position: relative;
}

.defaultWidth {
    width: 8rem;
    /* w-32 */
}

.widgetBody {
    padding: 0.5rem;
    /* p-2 */
}

.textContainer {
    height: 110px;
}

.widgetTitle {
    margin-bottom: 0.5rem;
    /* mb-2 */
    font-size: 1rem;
    /* text-base */
    font-weight: bold;
    line-height: 1.25;
    /* tracking-tight */
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: pre-line;
}

.widgetDescription {
    margin-bottom: 0.75rem;
    /* mb-3 */
    font-size: 1rem;
    /* font-normal */
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: pre-line;
}

.widgetButton {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    /* px-3 py-2 */
    font-size: 0.875rem;
    /* text-sm */
    font-weight: 500;
    /* font-medium */
    text-align: center;
    color: white;
    background-color: #6d28d9;
    /* bg-timesPurple */
    border-radius: 0.5rem;
    /* rounded-lg */
    text-decoration: none;
}

.widgetButton:hover {
    background-color: #5b21b6;
    /* hover:bg-purple-800 */
}

.widgetButton:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(96, 165, 250, 0.5);
    /* focus:ring-4 focus:ring-blue-300 */
}

.buttonText {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    white-space: pre-line;
}

.buttonIcon {
    width: 1rem;
    /* w-4 */
    height: 1rem;
    /* h-4 */
    margin-left: 0.5rem;
    /* ml-2 */
    margin-right: -0.25rem;
    /* -mr-1 */
}

.mainContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0 16px;
}

@media (min-width: 768px) {
    .mainContainer {
        flex-direction: row;
        margin: 0 90px;
    }
}

.flowRoot {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 350px;
    height: 500px;
    border: 1px solid #e5e7eb;
    /* border-gray-200 */
    border-top-left-radius: 0.5rem;
    /* Applies border radius to the top left corner */
    border-top-right-radius: 0.5rem;
    /* Applies border radius to the top right corner */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    /* shadow-md */
    padding: 10px;
}

.articleList {
    list-style-type: none;
    padding: 0;
    margin: 0;
    /* border-top: 1px solid #e5e7eb; divide-y divide-gray-200 */
}

.articleItem {
    padding: 0.5rem 0;
    /* py-2 */
    border-bottom: 1px solid #e5e7eb;
    /* divide-y divide-gray-200 */
}

.viewAllContainerDesktop {
    display: none;
}

.viewAllButtonDesktop {
    display: block;
    width: 100%;
    border-radius: 0 0 0.5rem 0.5rem;
    /* rounded-bl-md rounded-br-md */
    border: none;
    background-color: #6d28d9;
    /* bg-timesPurple */
    padding: 0rem 1rem;
    /* px-4 py-2 */
    text-align: center;
    font-size: 0.875rem;
    /* text-sm */
    font-weight: 500;
    /* font-medium */
    color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    /* shadow-sm */
    text-decoration: none;
    padding: 10px 0;
}

.viewAllButtonDesktop:hover {
    background-color: #5b21b6;
    /* hover:bg-purple-800 */
}

.viewAllButtonDesktop:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(96, 165, 250, 0.5);
    /* focus:ring-4 focus:ring-blue-300 */
}

.viewAllContainerMobile {
    display: flex;
    justify-content: flex-end;
}

.viewAllButtonMobile {
    display: inline-flex;
    align-items: center;
    padding-right: 1rem;
    /* pr-4 */
    font-size: 0.875rem;
    /* text-sm */
    font-weight: 500;
    /* font-medium */
    color: #6d28d9;
    /* text-timesPurple */
    text-decoration: none;
}

.viewAllIcon {
    width: 1rem;
    /* w-4 */
    height: 1rem;
    /* h-4 */
    margin-left: 0.5rem;
    /* ml-2 */
    margin-right: -0.25rem;
    /* -mr-1 */
}

.lineClamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    white-space: pre-line;
}

@media (min-width: 640px) {

    /* sm breakpoint */
    .viewAllContainerDesktop {
        display: block;
    }

    .viewAllContainerMobile {
        display: none;
    }
}

.cardLink {
    position: relative;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: inherit;
}

.cardContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.cardTitle {
    font-size: 1rem;
    /* text-base */
    margin-bottom: 0rem;
    /* mb-2 */
    font-weight: 600;
    /* font-semibold */
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: pre-line;
}

.cardDescription {
    font-size: 0.875rem;
    /* text-sm */
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    margin-bottom: 0px;

}

.lineClamp2 {
    -webkit-line-clamp: 2;
}

.lineClamp5 {
    -webkit-line-clamp: 5;
}

.cardDate {
    white-space: nowrap;
    font-size: 0.875rem;
    /* text-sm */
    color: #6b7280;
    /* text-gray-500 */
    margin-top: 0.5rem;
    /* mt-2 */
}

.sidebar {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    position: -webkit-sticky;
    /* For Safari */
    position: sticky;
    top: 0;
    /* Adjust as needed for your layout */
}