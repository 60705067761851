.loadingLayout {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainContainer {
  width: 100%;
  height: 100vh;
  overflow: auto;
  /* padding-bottom: 20px; */
}

.count {
  color: #fff;
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}

.paginationDiv {
  display: flex;
  width: 100%;
  /* align-self: baseline; */
}