.NewArticle {
  border: 1px solid #1d1b1b;
  height: 86vh;
  overflow: auto;
  flex: 5;
}

.ArticleHeading {
  background-color: #287d61;
  font-weight: 500;
  padding: 10px;
  margin: 0;
}
.articleImage {
  border-radius: 2px;
  height: 100px;
}
.FormContainer {
  display: flex;
  height: 95%;
  background-color: #333;
}

.leftSide {
  flex: 5;
  padding: 10px 35px;
}

.formGroup {
  margin: 10px 0;
}

.formGroup label {
  font-weight: 400;
  font-family: inherit;
  color: white;
}

.formGroup input {
  font-family: inherit;
  padding: 2px 5px;
  height: 35px;
  border-radius: 10px;
  background-color: #1d1b1b;
  color: white;
  width: 100%;
}

.formGroup textarea {
  padding: 5px;
  font-family: inherit;
  resize: none;
  overflow-y: auto;
  display: block;
  width: 100%;
  border-radius: 10px;
  background-color: #1d1b1b;
  color: white;
}

.formGroup input[name="title"] {
  width: 100%;
  display: block;
  height: 35px;
  border-radius: 10px;
}

.NewArticle input:focus,
textarea:focus {
  border-color: #007084;
  outline: none;
}

.content {
  width: 605px;
  height: 300px;
  border: 1px solid grey;
  overflow-y: auto;
}

.imageVideo {
  display: flex;
  justify-content: space-between;
}

.leftSideImage {
  border-radius: 10px;
  width: 100%;
}

.imageVideo input[type="file"] {
  height: 30px;
  border-radius: 10px;
  padding: 0;
}

.submitDiv {
  text-align: center;
  padding-top: 10px;
}

.submitButton {
  font-family: inherit;
  background: #f44336;
  color: white;
  padding: 5px 10px;
  border-radius: 6px;
  cursor: pointer;
  border: none;
}

.rightSide {
  flex: 2;
  padding: 10px;
  border-left: 3px solid rgb(204, 194, 194);
}

.rightSide input[type="text"] {
  width: 100%;
  padding: 2px 5px;
  height: 35px;
  border-radius: 10px;
  background-color: #1d1b1b;
  color: white;
}

.rightSide select {
  width: 100%;
  padding: 2px 5px;
  height: 35px;
  border-radius: 10px;
  background-color: #1d1b1b;
  color: white;
}

.rightSide input[type="file"] {
  border: 1px solid grey;
  width: 100%;
  padding: 0;
  height: 30px;
  border-radius: 10px;
  background-color: #1d1b1b;
  color: white;
}

.rightSideImage {
  border-radius: 10px;
  width: 100%;
  height: 150px;
}

/* ----------------------------------------------------------------------------------------------------------------------------------- */
.formGroup_TextTitle {
  font-size: 20px;
  color: #fff;
}
.artical_date {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}
.artical_dat {
  color: #fff;
  font-weight: 400;
  font-size: 17px;
}
.infoheading {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}
.Img_Artical {
  text-align: center;
  margin-top: 20px;
}
.otherDetailHeading {
  border-bottom: 3px solid #525050;
  padding-bottom: 5px;
  color: white;
  font-size: 20px;
}
.IconImage {
  width: 20%;
  padding: 10px;
}

.flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
