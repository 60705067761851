.mainDiv {
  width: 100%;
  color: #fff;
  padding: 20px;
  /* text-align: center; */
}
.header {
  width: 100%;
  background-color: rgb(48, 46, 46);
  text-align: left;
  padding: 10px;
}
.mainDiv > div {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.mainDiv > div > h6 {
  width: 200px;
  margin-bottom: 0;
}
.mainDiv > div > input {
  width: 200px;
}
.submitBtn {
  justify-content: flex-end;
  width: 100%;
}
.submitBtn > button {
  background-color: rgb(204, 31, 31);
  border: none;
  color: #fff;
  border-radius: 5px;
  padding: 0px 30px;
}
.allAds {
  /* display: flex; */
}
.allAds > div {
  display: flex;
  margin-bottom: 10px;
}
.allAds > div > p {
  font-size: 15px;
  margin: 0;
  color: #fff;
}
.allAds > div > h6 {
  width: 200px;
  margin-bottom: 0;
}
.table {
  color: #fff;
  width: 100%;
}
.table th,
.table td {
  border: 1px solid #fff;
  text-align: center;
  padding: 10px;
}
.forms {
  display: flex;
}
