.input {
  display: flex;
  flex-direction: column;
}
.fieldName {
  margin: 10px 10px;
  font-size: 18px;
}
.fields1,
.fields2 {
  margin: 10px 10px;
}
.inputField {
  width: 34vw;
  padding: 10px;
  background-color: #171717;
  color: #fff;
  border: none;
  border-bottom: 1.5px solid gray;
}
.dropdownInput {
  /* width: 35vw; */
  padding: 10px;
  margin: 15px;
  margin-left: 60px;
  background-color: #171717;
  color: #fff;
  border: 1.5px solid gray;
}
.countrydropdown {
  width: 36vw;
  padding: 10px;
  /* margin: 15px; */
  background-color: #171717;
  color: #fff;
  border: none;
  border-bottom: 1.5px solid gray;
}
.multiLineTextBox {
  height: 11vh;
  width: 34vw;
  padding: 10px;
  background-color: #171717;
  color: #fff;
  border: none;
  border: 1.5px solid gray;
}
.image_label {
  width: 34vw;
  border-radius: 5px;
  margin: 5px;
  background-color: white;
  color: black;
}
.checkbox_input {
  height: 3vh;
  width: 3vw;
}
.fieldName1 {
  text-align: center;
  margin-top: 8px;
}
::placeholder {
  color: rgb(160, 156, 156);
  /* font-size: 18px; */
}
.checkbox_input:checked:before {
  background-color: green;
}
/* ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  } */
.image_label {
  width: 120px;
  padding: 10px;
}
.option {
  padding: 15px;
}

@media screen and (min-width: 1440px) {
  .image_label {
    width: 36vw;
  }
  .inputField {
    width: 36vw;
  }
  .dropdownInput {
    width: 37vw;
  }
  .multiLineTextBox {
    width: 36vw;
  }
}
.inputArea_withIcon{
    padding: 12px;
    border: 1px solid gray;
    width: 80%;
    border: none;
    outline: none;
    border-radius: 10px;
    background-color: #171717;
    color: #fff;
  }
  .input_fields{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
    border: none;
    border-bottom: 1.5px solid gray;
    /* border-radius: 10px; */
  }
  .eyeIcon {
    margin-right: 10px;
    height: 20px;
    width: 20px;
  }
  .MainContainer{
      width: 36vw;
      margin: 12px;
  }
  .CheckBoxContainer{
      width: 75vw;
      display: flex;
      margin-bottom: 18px;
      margin-top: 15px;
  }