.Add_event_button {
    background-color: #f02f39;
    color: #fff;
    font-size: 22px;
    border: none;
    text-align: center;
     width: 8vw;
     border-radius: 6px;
  }
  .Add_event_button:hover{
    background-color:#fff;
    color: #f02f39;
  }